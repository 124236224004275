import React, { Component } from "react";
import Modal from "react-modal";
import CoinflipInventoryItem from "../../../containers/Coinflip/CoinflipInventoryItem";
import { NotificationManager } from "react-notifications";
import config from "../../../config";

const IMAGE_URL = "https://steamcommunity-a.akamaihd.net/economy/image/";
const { minItems, maxItems, minAmount, itemThreshold } = config.jackpot;

export default class JackpotDepositModal extends Component {
  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.afterOpen = this.afterOpen.bind(this);
    this.forceRefresh = this.forceRefresh.bind(this);
    this.depositItems = this.depositItems.bind(this);

    this.state = {
      selectedItems: [],
    };
  }

  depositItems() {
    const betValue = this.getTotalSelectedPrice(),
      itemsSelected = this.getSelectedCount();
    if (betValue < minAmount) {
      return NotificationManager.error(`Bet must be at least $${minAmount}`);
    } else if (itemsSelected < minItems || itemsSelected > maxItems) {
      return NotificationManager.error(
        `You must select between ${minItems} and ${maxItems} items`
      );
    }
    this.onClose();
    this.props.depositItems(this.getSelectedItems());
  }

  afterOpen() {
    this.props.loadInventory();
  }

  onClose() {
    this.setState({ selectedItems: [] });
    this.props.onClose();
  }

  renderItems() {
    const { items, error, loading } = this.props.inventory;
    if (loading) {
      return (
        <span className="InventoryLoader">
          <div className="sk-chase">
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
          </div>
        </span>
      );
    } else if (error && items.length === 0) {
      return (
        <div className="InventoryLoadError">
          <span>
            An error ocurred loading your inventory. You may not have any
            tradeable {config.metadata.gameName} items or Steam is offline.
          </span>
        </div>
      );
    }

    return items.map((item, key) => (
      <CoinflipInventoryItem
        name={item.name}
        image={`${IMAGE_URL}${item.icon_url}`}
        selected={this.isSelected(key)}
        price={item.price}
        key={key}
        disabled={item.price < itemThreshold}
        unselect={() => this.unselectItem(key)}
        select={() => this.selectItem(key)}
      />
    ));
  }

  forceRefresh() {
    this.setState({ selectedItems: [] });
    this.props.forceRefreshInventory();
  }

  render() {
    const betValue = this.getTotalSelectedPrice(),
      itemsSelected = this.getSelectedCount();
    const betClass = betValue >= minAmount ? "good" : "bad";
    const itemsClass = itemsSelected >= minItems &&
    itemsSelected <= maxItems
      ? "good"
      : "bad";
    const buttonState =
      betValue >= minAmount &&
      itemsSelected >= minItems &&
      itemsSelected <= maxItems
        ? false
        : true;

    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.onClose}
        closeTimeoutMS={200}
        onAfterOpen={this.afterOpen}
        contentLabel="Modal"
        className="Modal ModalCreateCoinflip ModalCreateCoinflipCreated"
        overlayClassName="Modal__Overlay"
      >
        <div className="ModalCreateBox">
          <div className="ModalHeader">
            <span>Jackpot Deposit</span>
            <div className="UserInventoryInfo">
              <span title="Inventory Value">
                <i className="fas fa-dollar-sign"></i>
                {this.getTotalInventoryPrice()}
              </span>
              <span title="Inventory Items">
                <i className="fas fa-briefcase"></i>
                {this.getInventoryItemsCount()}
              </span>
            </div>
          </div>
          <div className="ModalContent">
            <div className="DepositPanel">
              <div className="RequiedValues">
                <span>Min bet: ${minAmount}</span>
                <span>Max items: {maxItems}</span>
              </div>
              <button
                className="DepositRefresh"
                onClick={this.props.forceRefreshInventory}
              >
                <i className="fas fa-sync-alt" />
              </button>
            </div>

            <div className="ItemsBox">
              <div className="ItemsWrapper">{this.renderItems()}</div>
            </div>
          </div>
          <div className="ModalFooter ModalFooterDeposit">
            <div className="FooterInfo">
              <div className="DepositValue">
                <span>Bet Value</span>
                <i className={`fas fa-dollar-sign ${betClass}`}></i>
                <span className="Value">{this.getTotalSelectedPrice()}</span>
              </div>
              <div className="DepositValue">
                <span>Selected items</span>
                <i className={`fas fa-inbox ${itemsClass}`}></i>
                <span className="Value">{`${itemsSelected}/${maxItems}`}</span>
              </div>
            </div>
            <div className="FooterButtons">
              <button onClick={this.onClose}>Cancel</button>
              <button
                className="Accept"
                disabled={buttonState}
                onClick={this.depositItems}
              >
                Deposit
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  /* HELPER METHODS */

  getSelectedCount() {
    return this.state.selectedItems.length;
  }

  getSelectedItems() {
    const array = [];
    for (var index in this.state.selectedItems) {
      array.push(this.props.inventory.items[this.state.selectedItems[index]]);
    }
    return array;
  }

  getTotalSelectedPrice() {
    let total = 0.0;
    for (var index in this.state.selectedItems) {
      total += parseFloat(
        this.props.inventory.items[this.state.selectedItems[index]].price
      );
    }
    return total.toFixed(2);
  }

  getTotalInventoryPrice() {
    let total = 0.0;
    for (var index in this.props.inventory.items) {
      const item = this.props.inventory.items[index];
      if (item.amount) {
        total += parseFloat(item.price * item.amount);
      }
    }
    return total.toFixed(2);
  }

  getInventoryItemsCount() {
    let itemsCount = this.props.inventory.items.length;
    return itemsCount;
  }

  isSelected(key) {
    for (var index in this.state.selectedItems) {
      if (this.state.selectedItems[index] === key) {
        return true;
      }
    }
    return false;
  }

  selectItem(index) {
    this.setState({
      selectedItems: this.state.selectedItems.concat([index]),
    });
  }

  unselectItem(index) {
    this.setState({
      selectedItems: this.state.selectedItems.filter((el) => el !== index),
    });
  }

  clearItems() {
    this.setState({
      selectedItems: [],
    });
  }

  sortItems(items) {
    items.forEach(function (item, index) {
      item.origOrder = index;
    });
    return items.sort((a, b) => {
      const diff = parseFloat(b.price) - parseFloat(a.price);
      if (diff === 0) {
        return a.origOrder - b.origOrder;
      }
      return diff;
    });
  }
}
