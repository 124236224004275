import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AdminWithdrawsTable from './AdminWithdrawsTable'
import { Helmet } from 'react-helmet-async';
import { Dropdown } from 'semantic-ui-react';


import {
  setWithdrawCompleted,
  getUsersWithReferralWithdraws,
} from '../../../actions';

import config from '../../../config';
import '../Admin.css';

const sortOptions = [
  {
    key: 'not completed',
    text: 'Not completed',
    value: 'not completed'
  },
  {
    key: 'completed',
    text: 'Completed',
    value: 'completed'
  },
  {
    key: 'date descending',
    text: 'Date Descending',
    value: 'date descending'
  },
  {
    key: 'date ascending',
    text: 'Date Ascending',
    value: 'date ascending'
  }
]


class AdminWithdraws extends Component {

  componentWillMount() {
    this.props.getUsersWithReferralWithdraws();
    this.state = {
      filter: 'not completed',
    }
  }

  onDropdownChange(data) {
    this.setState({ filter: data.value })
  }

  render() {
    const { pendingWithdraws } = this.props;
    const { filter } = this.state
    return (
      <div className="container">
        <Helmet>
          <title>Referral Withdraws - {config.metadata.name}</title>
          <meta
            name="description"
            content="Referral Withdraws - {config.metadata.name}"
          />
        </Helmet>
        <div className="AdminHeader">
          <Dropdown className="AdminDropdown" options={sortOptions} onChange={(event, data) => this.onDropdownChange(data)} />
        </div>
        <AdminWithdrawsTable
          pendingWithdrawsWithUser={this.props.pendingWithdraws.withdraws}
          setWithdrawCompleted={this.props.setWithdrawCompleted}
          filter={filter}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pendingWithdraws: state.admin.pendingWithdraws,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setWithdrawCompleted,
      getUsersWithReferralWithdraws,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminWithdraws);
