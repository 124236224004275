import React, { Component } from 'react'
import { Popup } from 'semantic-ui-react'

export default class CoinflipItem extends Component {

  getComponent() {
    return (
      <div className="CoinflipItem">
        <img src={this.props.image} alt="{this.props.name}" />
        <a href={"https://steamcommunity.com/market/listings/440/"+this.props.name} target="_blank" className={"historyprice"+(this.props.price<2.8?" cheap":"")}>{this.props.price}</a>
      </div>
    )
  }

  render() {
    return (
      <Popup
        className="chatPopup"
        size='mini'
        position="top center"
        trigger={this.getComponent()}
        content={`${this.props.name+ (this.props.image.split('#')[3] ? ' ' + decodeURI(this.props.image.split('#')[3].split('#')[0]) : '')}`} />
    )
  }

}
