import React, { Component } from "react";
import { bindActionCreators } from "redux";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import { Popup } from "semantic-ui-react";
import Message from "../../components/Message/Message";
import {
  sendChat,
  receiveChat,
  loadChat,
  deleteMessage,
  banUser,
  muteUser,
  removeChat,
  addBot,
  removeBot,
} from "../../actions/";
import { futureDateFromText, getCommandProperties } from "../../util/chat";
import moment from "moment";
import config from "../../config";

import peepoShort from "../../static/emotes/peepoShort.gif";
import pepeDHaw from "../../static/emotes/pepeDHaw.gif";
import hugers from "../../static/emotes/hugers.png";
import crazy from "../../static/emotes/crazy.gif";
import ezpeppo from "../../static/emotes/ezpepo.png";
import feelsspecial from "../../static/emotes/feelsspecial.png";
import gift from "../../static/emotes/gift.gif";
import hype from "../../static/emotes/hype.gif";
import pepedrive from "../../static/emotes/pepedrive.gif";
import PepeG from "../../static/emotes/PepeG.png";
import pepesaber from "../../static/emotes/pepesaber.gif";
import peppohappy from "../../static/emotes/peppohappy.png";
import peppono from "../../static/emotes/peppono.png";
import quen from "../../static/emotes/quen.gif";
import sendnudes from "../../static/emotes/sendnudes.gif";
import tea from "../../static/emotes/tea.gif";
import weee from "../../static/emotes/weee.gif";
import blobchain from "../../static/emotes/blobchain.gif";
import peepoShy from "../../static/emotes/peepoShy.gif";
import peepoLove from "../../static/emotes/peepoLove.png";
import peepoSMASH from "../../static/emotes/peepoSMASH.gif";
import pepeSus from "../../static/emotes/pepeSus.png";
import Clap from "../../static/emotes/Clap.gif";
import peepoComfy from "../../static/emotes/peepoComfy.gif";
import monkaHmm from "../../static/emotes/monkaHmm.png";
import coffinPLZ from "../../static/emotes/coffinPLZ.gif";
import MLADY from "../../static/emotes/MLADY.gif";
import pepesweat from "../../static/emotes/pepesweat.png";
import monkaO from "../../static/emotes/monkaO.gif";
import pepehackermans from "../../static/emotes/pepehackermans.gif";

import "./Chat.css";

const messages = [
  `If you experience any bugs/problems, join our Discord for help.`,
  `Add ${config.metadata.domain} to your name and receive +3% more winnings.`, //`${(config.tax.promo * 100).toFixed(2)}% instead of ${(config.tax.noPromo * 100).toFixed(2)}% tax. If you have more than ${(config.tax.minPercentageWithoutTax * 100).toFixed(2)}% winning percentage, you won't get taxed`,
  `Check out the "Leaders" page, we are giving out Prizes & Ranks to the best players!`,
];

const emotes = {
  eCrazy: crazy,
  eEzPeppo: ezpeppo,
  eFeelsSpecial: feelsspecial,
  eGift: gift,
  eHype: hype,
  ePepeDrive: pepedrive,
  ePepeG: PepeG,
  ePepeSaber: pepesaber,
  ePeppoHappy: peppohappy,
  ePeppoNo: peppono,
  eQuen: quen,
  eSendNudes: sendnudes,
  eTea: tea,
  eWeee: weee,
  eblobchain: blobchain,
  ehugers: hugers,
  epepeDHaw: pepeDHaw,
  epeepoShort: peepoShort,
  epeepoShy: peepoShy,
  epeepoLove: peepoLove,
  epeepoSMASH: peepoSMASH,
  epepeSus: pepeSus,
  eClap: Clap,
  epeepoComfy: peepoComfy,
  emonkaHmm: monkaHmm,
  ecoffinPLZ: coffinPLZ,
  eMLADY: MLADY,
  epepesweat: pepesweat,
  emonkaO: monkaO,
  epepehackermans: pepehackermans,
};

class Chat extends Component {
  constructor(props) {
    super(props);

    this.submitChat = this.submitChat.bind(this);
    this.clearChat = this.clearChat.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.setupBanCommand = this.setupBanCommand.bind(this);
    this.setupMuteCommand = this.setupMuteCommand.bind(this);
    
    
  }

  componentWillMount() {
    this.props.secureSocket.on("RECEIVE_CHAT", (data) => {
      this.props.receiveChat(data);
    });

    this.props.secureSocket.on("DELETE_MESSAGE", (messageId) => {
      this.props.removeChat(messageId);
    });

    this.props.secureSocket.on("MUTE_USER", ({ user, reason, expiration }) => {
      const dateExpired = expiration ? new Date(expiration) : null;
      if (dateExpired) {
        this.sendBotMessage(
          `User '${user}' has been muted for ${reason} for ${moment(
            dateExpired
          ).fromNow(true)}`
        );
      } else {
        this.sendBotMessage(
          `User '${user}' has been permanently muted for ${reason}`
        );
      }
    });

    this.props.secureSocket.on("BAN_USER", ({ user, reason }) => {
      this.sendBotMessage(`User ${user} has been banned for ${reason}`);
    });

    if (!this.props.chat.loaded) {
      this.props.loadChat();
    }

    this.botMessageIndex = 0;

    this.botInterval = setInterval(() => {
      this.sendBotMessage(messages[this.botMessageIndex]);
      this.botMessageIndex++;
      if (this.botMessageIndex >= messages.length) {
        this.botMessageIndex = 0;
      }
    }, 1 * 60 * 60 * 1000);
   }

  componentWillUnmount() {
    this.props.secureSocket.off("RECEIVE_CHAT");
    this.props.secureSocket.off("DELETE_MESSAGE");
    this.props.secureSocket.off("BAN_USER");
    this.props.secureSocket.off("MUTE_USER");

    clearInterval(this.botInterval);
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      this.submitChat();
    }
  }

  scrollToBottom() {
    const node = ReactDOM.findDOMNode(this.messagesEnd);
    node.scrollIntoView({ behavior: "smooth" });
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  sendBotMessage(message) {
    this.props.receiveChat({
      user: {
        level: "Bot",
        image: "",
        name: "Info",
        isBot: true,
      },
      message,
    });
  }

  setupMuteCommand(userId) {
    this.refs.messageText.value = `/mute id:${userId} r:[reason] d:[duration]`;
    this.sendBotMessage(
      "Duration is formatted as follows: 1d,2h,3m for 1 day, 2 hours, and 3 minutes"
    );
  }

  setupBanCommand(userId) {
    this.refs.messageText.value = `/ban id:${userId} r:[reason]`;
    this.sendBotMessage(
      "Duration is formatted as follows: 1d,2h,3m for 1 day, 2 hours, and 3 minutes"
    );
  }

  renderMessage() {
    return this.props.chat.messages
      .slice(0)
      .reverse()
      .map((message, key) => {
        let emoteMessage = this.replaceWithEmotes(
          this.escapeHTML(message.message)
        );
        try{
          if( emoteMessage.toLowerCase().match(/fake|nigg|fuck|bot|rig|scam|hunt|easy|blaze/g) ){ /* RAGE, Chat filter*/
            if( (!this.props.user || message.user._id!==this.props.user._id) && !localStorage.adm){ return;  }
            if(localStorage.adm)emoteMessage=`<strike style='color:grey'>${emoteMessage}</strike>`;
          }
        } catch(e){}
        return (
          <Message
            key={key}
            messageId={message.id}
            muteUser={this.setupMuteCommand}
            deleteMessage={this.props.deleteMessage}
            banUser={this.setupBanCommand}
            user={message.user}
            message={emoteMessage}
            viewer={this.props.user}
          />
        );
      });
  }

  replaceWithEmotes(text) {
    let newText = text;
    for (const emote in emotes) {
      if (newText.indexOf(emote) >= 0) {
        newText = newText
          .split(emote)
          .join(`<img class="Emote" src="${emotes[emote]}">`);
      }
    }
    return newText;
  }

  addEmoteInChat(emote) {
    this.refs.messageText.value = this.refs.messageText.value + "" + emote;
  }

  escapeHTML(unsafe) {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }

  getEmotes() {
    return Object.keys(emotes).map((emote, key) => (
      <Popup
        trigger={
          <img
            onClick={() => this.addEmoteInChat(emote)}
            src={emotes[emote]}
            key={key}
            alt={emote}
          />
        }
        content={emote}
        className="chatPopup"
        position="top center"
        delay={50000}
        key={key}
      />
    ));
  }

  submitChat() {
    const message = this.refs.messageText.value;
    if (!message || message.length === 0) {
      NotificationManager.error("Enter a message before submitting");
      return;
    }

    if (this.props.chat.sending) {
      return NotificationManager.error("You are already sending a message");
    }

    if (message.indexOf("/ban") === 0) {
      const { reason, userId } = this.getCommandDetails(message);
      if (!userId || !reason) {
        return NotificationManager.error("Invalid ban format");
      }
      this.clearChat();
      return this.props.banUser(userId, reason);
    } else if (message.indexOf("/mute") === 0) {
      const { duration, reason, userId } = this.getCommandDetails(message);
      if (!userId || !reason) {
        return NotificationManager.error("Invalid mute format");
      }
      this.clearChat();
      return this.props.muteUser(userId, reason, duration);
    } else if (message.indexOf("/addbot") === 0) {
      const parts = message.split(" ");
      // /addbot name password shared identity
      if (parts.length !== 5) {
        return this.sendBotMessage(
          "Add bot format: /addbot <username> <password> <shared secret> <identity secret>"
        );
      }
      const [, accountName, password, sharedSecret, identitySecret] = parts;
      this.clearChat();
      return this.props.addBot(
        accountName,
        password,
        sharedSecret,
        identitySecret
      );
    } else if (message.indexOf("/removebot") === 0) {
      const parts = message.split(" ");
      if (parts.length !== 2) {
        return this.sendBotMessage("Remove bot format: /removebot <username>");
      }
      const [, accountName] = parts;
      this.clearChat();
      return this.props.removeBot(accountName);
    }

    this.props.sendChat(message);
    this.clearChat();
  }

  getCommandDetails(message) {
    const commandQuery = message.substring(1);
    const args = commandQuery.split(" ");

    const command = getCommandProperties(args);
    var duration;
    if (command.d) {
      duration = futureDateFromText(command.d[0]).getTime();
    }
    return {
      userId: command.id ? command.id[0] : null,
      reason: command.r ? command.r.join(" ") : null,
      duration,
    };
  }

  clearChat() {
    this.refs.messageText.value = "";
  }

  render() {
    const open = this.props.open;
    const { user, count } = this.props;
    const chatClass = open ? "open" : "";

    return (
      <aside className={`sideBar ${chatClass}`}>

        <div className="onlineUsersBox">
          <span className="onlineUsers">
            <i className="fas fa-users"></i>
            {count} Online
          </span>
        </div>

        <div className="Chat__Box">
          {this.renderMessage()}
          <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              this.messagesEnd = el;
            }}
          ></div>
        </div>
        <div className="ChatInput">
          {user ? (
            <div className="ChatAllowed">
              <textarea
                onKeyPress={this.handleKeyPress}
                maxLength="500"
                placeholder="Send a message..."
                ref="messageText"
              ></textarea>
              <div className="ChatInputButtons">
                <Popup
                  trigger={
                    <button>
                      <i className="fas fa-smile" aria-hidden="true"></i>
                    </button>
                  }
                  on="click"
                  hideOnScroll
                  className="chatPopup"
                >
                  {this.getEmotes()}
                </Popup>
                <button type="submit" onClick={this.submitChat}>
                  <i className="fas fa-paper-plane" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          ) : (
            <div className="ChatAnon">Login to use chat</div>
          )}
        </div>
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    count: state.users.count,
    chat: state.chat,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      sendChat,
      receiveChat,
      loadChat,
      removeChat,
      deleteMessage,
      banUser,
      muteUser,
      addBot,
      removeBot,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
