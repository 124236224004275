import React, { Component } from 'react'
import { Popup } from 'semantic-ui-react'

const IMAGE_URL = 'https://steamcommunity-a.akamaihd.net/economy/image/'

export default class JackpotDeposit extends Component {

  constructor(props) {
    super(props)

    this.state = {
      expanded: false
    }
  }

  getTotal(deposit) {
    let total = 0.00
    for (const index in deposit.items) {
      total += parseFloat(deposit.items[index].price)
    }
    return total
  }

  renderItems() {
    const { color } = this.props
    return this.props.deposit.items.map((item, key) => (
      <Popup 
        key={key}
        content={
        <span>
          <img src={`${IMAGE_URL}${item.icon_url}`} alt="{item.name}"/>
          <p className="price">{item.price}</p>
          <p className="name">{item.name+ (item.icon_url.split('#')[3] ? ' ' + decodeURI(item.icon_url.split('#')[3].split('#')[0]) : '')}</p>
          <span className="userColor" style={{backgroundColor:`#${color}`}}></span>
        </span>
        }
        trigger={
          <img key={key} src={`${IMAGE_URL}${item.icon_url}`} alt="{item.name}"/>
        }
        position="top center"
        className="itemPopup"
      />
    ))
  }

  render() {
    const { deposit } = this.props
    const { color } = this.props
    const { chances } = this.props
    const ended = this.props.ended

    const renderDeposit = (
      <div className="RoundDeposit" style={{borderColor: `#${color}`, width: `${!ended ? (chances * 100).toFixed(2) : 100}%`}}>
        <div className="DepositInfo">
          <a target="_blank" href={`https://steamcommunity.com/profiles/${deposit.id}`} rel="noopener noreferrer">
            <img src={deposit.image} alt={deposit.name} style={{borderColor:`#${color}`, boxShadow: `#${color} 0 0 6px 0`}}/>
          </a>
          <span className="DepositName">{deposit.name}</span>
          <span className="DepositValue">${Number(this.getTotal(deposit)).toFixed(2)}</span>
          <div className="DepositChances">{(chances * 100).toFixed(2)}%</div>
        </div>
        
        <div className="DepositItems">
          { this.renderItems()}
        </div>
      </div>
    )

    if(!ended) {
      return (
        <Popup
          key={this.props.key}
          content = {`${deposit.name} | $${Number(this.getTotal(deposit)).toFixed(2)} | ${(chances * 100).toFixed(2)}%`}
          trigger = {renderDeposit}
          position="top center"
          className="chatPopup"
        />
      )
    }

    return renderDeposit
  }

}
