import React, { Component } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import './CountDownTimer.css'

export default class CountDownTimer extends Component {
  //props (totalSeconds, secondsRemaining, color)
  constructor(props) {
    super(props)

    this.tick = this.tick.bind(this)

    this.state = {
      percent: (props.secondsRemaining / props.totalSeconds),
      seconds: props.secondsRemaining,
    }

    this.interval = setInterval(this.tick, 1000)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      percent: (nextProps.secondsRemaining / nextProps.totalSeconds),
      seconds: nextProps.secondsRemaining
    })
  }

  tick() {
    if (this.props.freeze) {
      return
    }

    const seconds = this.state.seconds - 1

    if (seconds < 0) {
      this.setState({ percent: 0, seconds: 0 })
      clearInterval(this.interval)
      if (this.props.onComplete) {
        this.props.onComplete()
      }
    } else {
      this.setState({ percent: (seconds / this.props.totalSeconds), seconds })
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    this.interval = null
  }

  render() {
    const { percent, seconds } = this.state

    const jackpotTimer = (
      <div className="JackpotTimer">
        <div className="Visualiser">
          <span className="Bar" style={{width: `${percent * 100}%`}}></span>
        </div>
        <div className="Counter">
          <i className="fas fa-clock"></i> {seconds}
        </div>
      </div>
    )

    const coinflipTimer = (

      <CircularProgressbar 
        value={percent}
        maxValue={1}
        text={seconds}
        styles={buildStyles({
          pathTransitionDuration: 1,
          strokeLinecap: 'butt',
          pathColor: this.props.color,
          textColor: this.props.color,
          trailColor: '#050507',
          textSize: '30px',
        })}
      />
    )

    if(this.props.game === "jackpot") {
      return jackpotTimer
    }

    return coinflipTimer
  }

}
