import React, { Component } from "react";
import Modal from "react-modal";
import { NotificationManager } from "react-notifications";
import crypto from "crypto";
import md5 from 'md5'

import "./CoinflipVerifyModal.css";

export default class CoinflipVerifyModal extends Component {
  constructor(props) {
    super(props);

    this.verify = this.verify.bind(this);
  }

  verify() {
    const hash = this.refs.hash.value;
    const salt = this.refs.salt.value;
    const percent = this.refs.percent.value;


    if (!hash || !salt || !percent) {
      return NotificationManager.error("Not Verified");
    }

    const testHash = md5(`${salt}-${percent}`);

    if (testHash === hash) {
      document.querySelectorAll('.VerifyInputBox span').forEach((e)=>e.style.backgroundColor="#17b347"); document.querySelector(".ModalVerifyCoinflip span").innerHTML="✅- Verified (Valid Round)"
      return NotificationManager.success("Verified");
    }
    document.querySelectorAll('.VerifyInputBox span').forEach((e)=>e.style.backgroundColor="#563305"); document.querySelector(".ModalVerifyCoinflip span").innerHTML="Round could not be verified"
    NotificationManager.error("Not Verified");
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onClose}
        closeTimeoutMS={200}
        contentLabel="Modal"
        className={"Modal ModalVerifyCoinflip"}
        overlayClassName={"Modal__Overlay"}
      >
        <div className="ModalHeader">
          <span>Coinflip Verify</span>
          {/* <a onClick={this.props.onClose}><i className="fa fa-times" /></a> */}
        </div>
        <div className="ModalContent">
          <div className="VerifyDescription">
            The Provably Fair system uses an MD5 encryption of the winning
            percentage and a randomly generated salt to provide the user with a
            hash before the game has completed in order to reinforce the
            randomness to every coin flip.
          </div>

          <div className="VerifyInputs">
            <div className="VerifyInputBox">
              <span>Hash</span>
              <input
                defaultValue={this.props.game ? this.props.game.hash : null}
                ref="hash"
              />
            </div>
            <div className="VerifyInputBox">
              <span>Salt</span>
              <input
                defaultValue={this.props.game ? this.props.game.secret : null}
                ref="salt"
              />
            </div>
            <div className="VerifyInputBox">
              <span>Percent</span>
              <input
                defaultValue={
                  this.props.game ? this.props.game.winningPercentage : null
                }
                ref="percent"
              />
            </div>
          </div>
        </div>
        <div className="ModalFooter">
          <button onClick={this.props.onClose}>Close</button>
          <button className="Verify" onClick={this.verify}>
            Verify
          </button>
        </div>
      </Modal>
    );
  }
}
