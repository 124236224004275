import React, { Component } from "react";
import Modal from "react-modal";
import config from "../../../config";
import CoinflipInventoryItem from "../../../containers/Coinflip/CoinflipInventoryItem";
import { NotificationManager } from "react-notifications";
import { getCoinflipRange, getCoinflipTotal } from "../../../util/coinflip";

const IMAGE_URL = "https://steamcommunity-a.akamaihd.net/economy/image/";

const { minItems, maxItems, itemThreshold } = config.coinflip;

export default class CoinflipJoinModal extends Component {
  constructor(props) {
    super(props);

    this.afterOpen = this.afterOpen.bind(this);
    this.clearItems = this.clearItems.bind(this);
    this.joinGame = this.joinGame.bind(this);
    this.onClose = this.onClose.bind(this);

    this.state = {
      selectedItems: [],
    };
  }

  joinGame() {
    const betValue = this.getTotalSelectedPrice(),
      itemsSelected = this.getSelectedCount();
    const [priceLow, priceHigh] = getCoinflipRange(this.props.game);
    if (betValue < priceLow) {
      return NotificationManager.error(`Bet must be at least $${priceLow}`);
    } else if (itemsSelected < minItems || itemsSelected > maxItems) {
      return NotificationManager.error(
        `You must select between ${minItems} and ${maxItems} items`
      );
    } else if (betValue > priceHigh) {
      return NotificationManager.error(`Bet must be less than $${priceHigh}`);
    } else if (this.props.game.joiner.id) {
      return NotificationManager.error("Game is not open");
    }
    this.onClose();
    this.props.joinGame({
      game: this.props.game,
      items: this.getSelectedItems(),
    });
  }

  renderItems() {
    const { items, error, loading } = this.props.inventory;
    if (loading) {
      return (
        <span className="InventoryLoader">
          <div className="sk-chase">
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
          </div>
        </span>
      );
    } else if (error) {
      return (
        <div className="InventoryLoadError">
          <span>
            An error ocurred loading your inventory. You may not have any
            tradeable {config.metadata.gameName} items or Steam is offline.
          </span>
        </div>
      );
    }

    return items.map((item, key) => (
      <CoinflipInventoryItem
        name={item.name}
        image={`${IMAGE_URL}${item.icon_url}`}
        selected={this.isSelected(key)}
        price={item.price}
        key={key}
        disabled={item.price < itemThreshold}
        unselect={() => this.unselectItem(key)}
        select={() => this.selectItem(key)}
      />
    ));
  }

  sortItems(items) {
    items.forEach(function (item, index) {
      item.origOrder = index;
    });
    return items.sort((a, b) => {
      const diff = parseFloat(b.price) - parseFloat(a.price);
      if (diff === 0) {
        return a.origOrder - b.origOrder;
      }
      return diff;
    });
  }

  isSelected(key) {
    for (var index in this.state.selectedItems) {
      if (this.state.selectedItems[index] === key) {
        return true;
      }
    }
    return false;
  }

  selectItem(index) {
    this.setState({
      selectedItems: this.state.selectedItems.concat([index]),
    });
  }

  unselectItem(index) {
    this.setState({
      selectedItems: this.state.selectedItems.filter((el) => el !== index),
    });
  }

  clearItems() {
    this.setState({
      selectedItems: [],
    });
  }

  getSelectedCount() {
    return this.state.selectedItems.length;
  }

  getSelectedItems() {
    const array = [];
    for (var index in this.state.selectedItems) {
      array.push(this.props.inventory.items[this.state.selectedItems[index]]);
    }
    return array;
  }

  getTotalSelectedPrice() {
    let total = 0.0;
    for (var index in this.state.selectedItems) {
      total += parseFloat(
        this.props.inventory.items[this.state.selectedItems[index]].price
      );
    }
    return total;
  }

  getTotalInventoryPrice() {
    let total = 0.0;
    for (var index in this.props.inventory.items) {
      const item = this.props.inventory.items[index];
      if (item.amount) {
        total += parseFloat(item.price * item.amount);
      }
    }
    return total;
  }

  getInventoryItemsCount() {
    let itemsCount = this.props.inventory.items.length;
    return itemsCount;
  }

  getPercentage() {
    const total = parseFloat(getCoinflipTotal(this.props.game));
    const selectedPrice = parseFloat(this.getTotalSelectedPrice());

    const gameTotal = total + selectedPrice;

    return Number((selectedPrice / gameTotal) * 100).toFixed(2);
  }

  afterOpen() {
    this.props.loadInventory();
  }

  onClose() {
    this.setState({
      selectedItems: [],
    });
    this.props.onClose();
  }

  render() {
    const [priceLow, priceHigh] = getCoinflipRange(this.props.game);
    const betValue = this.getTotalSelectedPrice(),
      itemsSelected = this.getSelectedCount(),
      percentage = this.getPercentage();
    const betClass =
      betValue >= priceLow && betValue <= priceHigh ? "good" : "bad";
    const itemClass =
      itemsSelected >= minItems && itemsSelected <= maxItems ? "good" : "bad";
    const rangeClass = betValue >= priceLow && betValue <= priceHigh ? "good" : "bad";
    const buttonState =
      betValue >= priceLow &&
      betValue <= priceHigh &&
      itemsSelected >= minItems &&
      itemsSelected <= maxItems
        ? false
        : true;

    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.onClose}
        closeTimeoutMS={200}
        onAfterOpen={this.afterOpen}
        contentLabel="Modal"
        className="Modal ModalCreateCoinflip ModalCreateCoinflipCreated"
        overlayClassName="Modal__Overlay"
      >
        <div className="ModalCreateBox">
          <div className="ModalHeader">
            <span>Join Coinflip</span>
            <div className="UserInventoryInfo">
              <span title="Inventory Value">
                <i className="fas fa-dollar-sign"></i>
                {(this.getTotalInventoryPrice()).toFixed(2)}
              </span>
              <span title="Inventory Items">
                <i className="fas fa-briefcase"></i>
                {this.getInventoryItemsCount()}
              </span>
            </div>
          </div>
          <div className="ModalContent">
            <div className="DepositPanel">
              <div className="RequiedValues">
                <span>Min bet: ${Number(priceLow).toFixed(2)}</span>
                <span>Max items: {maxItems}</span>
                <span>Range: {`$${priceLow.toFixed(2)} - $${priceHigh.toFixed(2)}`}</span>
              </div>
              <button
                className="DepositRefresh"
                onClick={this.props.forceRefreshInventory}
              >
                <i className="fas fa-sync-alt" />
              </button>
            </div>

            <div className="ItemsBox">
              <div className="ItemsWrapper">{this.renderItems()}</div>
            </div>
          </div>
          <div className="ModalFooter ModalFooterDeposit">
            <div className="FooterInfo">
              <div className="DepositValue">
                <span>Bet Value</span>
                <i className={`fas fa-dollar-sign ${betClass}`}></i>
                <span className="Value">{(this.getTotalSelectedPrice()).toFixed(2)}</span>
                <span className={`Value ${betClass}`}>{`${betClass=="good"?"+":"-"}$${( (this.getTotalSelectedPrice()-priceLow)*(betClass=="bad"?-1:1) ).toFixed(2)}`}</span>
              </div>
              <div className="DepositValue">
                <i className={`fas fa-percentage ${rangeClass}`}></i>
                <span className="Value">{percentage}</span>
              </div>
            </div>
            <div className="FooterButtons">
              
              <button
                className="autoselect"
              >
                Auto select
              </button>
              <button
                className="Accept"
                disabled={buttonState}
                onClick={this.joinGame}
              >
                Join
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
