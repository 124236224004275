import React, { Component } from 'react';
import Modal from 'react-modal';
import { Input } from 'semantic-ui-react';
import CountUp from 'react-countup';
import moment from 'moment';

import './UserProfileModal.css';

export default class UserProfileModal extends Component {
  calculateLvl(totalWon, actualLevel) {
    let level = (-1 + Math.sqrt(totalWon) - actualLevel) * 100;

    return level;
  }

  levelColors(level) {
    let color;
    switch (true) {
      case level >= 100:
        color = "#A44CFC";
        break;
      case level >= 90:
        color = "#AFEA09";
        break;
      case level >= 80:
        color = "#139BE7";
        break;
      case level >= 70:
        color = "#E713E4";
        break;
      case level >= 60:
        color = "#E75F13";
        break;
      case level >= 50:
        color = "#E7D413";
        break;
      case level >= 40:
        color = "#13E773";
        break;
      case level >= 30:
        color = "#7013E7";
        break;
      case level >= 20:
        color = "#137AE7";
        break;
      case level >= 10:
        color = "#E71359";
        break;
      default:
        color = "#9b9b9b";
        break;
    }

    return color;
  }

  calculateCircle(levelProgress) {
    const circumference = 57 * 2 * Math.PI;
    const offset = circumference - (levelProgress / 100) * circumference;

    return [circumference, offset];
  }

  calculateBalance(totalBet, totalWon) {
    let balance = totalWon - totalBet;
    return balance;
  }

  render() {
    const { user } = this.props;
    const userLevelProgress = this.calculateLvl(user.totalBet, user.level);
    const balance = this.calculateBalance(user.totalBet, (user.totalWon*1.14));
    const circleValues = this.calculateCircle(userLevelProgress);
    const levelColor = this.levelColors(user.level);

    const balanceClass = balance > 0 ? "over" : "under";

    const tradeUrl = user.tradeUrl ? (user.tradeUrl): ("https://steamcommunity.com/...")

    const date = moment(new Date(user.dateJoined)).fromNow();

    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.close}
        closeTimeoutMS={200}
        contentLabel="Modal"
        className={"Modal TradeLinkModal"}
        overlayClassName={"Modal__Overlay"}
      >
        <div className="container User">
          <div className="UserAvatar">
            <svg className="ProgressRing">
              <circle
                className="ProgressRingCircle"
                stroke={levelColor}
                strokeWidth="3"
                fill="transparent"
                strokeDasharray={`${circleValues[0]}, ${circleValues[0]}`}
                strokeDashoffset={circleValues[1]}
                r="57"
                cx="65"
                cy="65"
              />
            </svg>
            <img src={user.image} alt="user image"/>
            <span className="UserLevel">{user.level}</span>
          </div>

          <span className="UserName">{user.name}</span>
          <a className="Logout" href={`/api/auth/logout`} > <i className="fas fa-sign-out-alt"></i><span>Logout</span> </a>
          <span className="UserId">{user._id}</span>
          <span className="UserDate">Joined {date}</span>

          <div className="TradeUrl">
            <p className={`${!user.tradeUrl?'tradeurlinfo':''}`}><i class="fa-brands fa-steam-symbol"></i> - Set your trade URL...</p>
            <p>
              <a href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url" target="_blank" rel="noopener norefferer">
                <button>Find your trade url </button>
              </a>
            </p>
            <Input
              placeholder={tradeUrl}
              className="TradeUrlInput"
              ref="input"
            />
            <button className="SaveUrl Modal__TradeURL-Save" onClick={() => this.props.onClick(this.refs.input)} >
              Save
            </button>
          </div>

          <div className="UserStats">
            <div className="UserStat Bet">
              <CountUp prefix={"$"} start={0} end={user.totalBet.toFixed(2)} duration={1} decimals={2} />
              <p>Total Bet</p>
            </div>
            <div className="UserStat Won">
              <CountUp prefix={"$"} start={0} end={(user.totalWon*1.14).toFixed(2)} duration={1} decimals={2} />
              <p>Total Won</p>
            </div>
            <div className={`UserStat Balance ${balance != 0 ? balanceClass : ""}`} >
              <CountUp prefix={"$"} start={0} end={balance.toFixed(2)} duration={1} decimals={2} />
              <p>Profit</p>
            </div>
          </div>
        </div>
        <div className="ModalFooter">
          <button onClick={this.props.close}>Close</button>
        </div>
      </Modal>
    );
  }
}
