export const ADMIN_LOAD_RAKE = Symbol('ADMIN_LOAD_RAKE')
export const ADMIN_LOAD_RAKE_SUCCESS = Symbol('ADMIN_LOAD_RAKE_SUCCESS')
export const ADMIN_LOAD_RAKE_FAILURE = Symbol('ADMIN_LOAD_RAKE_FAILURE')

export const ADMIN_WITHDRAW_RAKE = Symbol('ADMIN_WITHDRAW_RAKE')
export const ADMIN_WITHDRAW_RAKE_SUCCESS = Symbol('ADMIN_WITHDRAW_RAKE_SUCCESS')
export const ADMIN_WITHDRAW_RAKE_FAILURE = Symbol('ADMIN_WITHDRAW_RAKE_FAILURE')

export const ADMIN_GET_USERS_WITH_REFERRAL_WITHDRAWS = Symbol('ADMIN_GET_USERS_WITH_REFERRAL_WITHDRAWS')
export const ADMIN_GET_USERS_WITH_REFERRAL_WITHDRAWS_SUCCESS = Symbol('ADMIN_GET_USERS_WITH_REFERRAL_WITHDRAWS_SUCCESS')
export const ADMIN_GET_USERS_WITH_REFERRAL_WITHDRAWS_FAILURE = Symbol('ADMIN_GET_USERS_WITH_REFERRAL_WITHDRAWS_FAILURE')

export const ADMIN_REFERRAL_WITHDRAW_SET_COMPLETED = Symbol('ADMIN_REFERRAL_WITHDRAW_SET_COMPLETED')
export const ADMIN_REFERRAL_WITHDRAW_SET_COMPLETED_SUCCESS = Symbol('ADMIN_REFERRAL_WITHDRAW_SET_COMPLETED_SUCCESS')
export const ADMIN_REFERRAL_WITHDRAW_SET_COMPLETED_FAILURE = Symbol('ADMIN_REFERRAL_WITHDRAW_SET_COMPLETED_FAILURE')
