export function sortItems(items) {
  items.forEach(function(item, index) {
    item.origOrder = index;
  })
  return items.sort((a, b) => {
    const diff = parseFloat(b.price) - parseFloat(a.price)
    if (diff === 0) {
      return a.origOrder - b.origOrder
    }
    return diff
  })
}

export function seperateItems(items) {
  const itemsSeperatedByAmount = [];

  items.map((item) => {
    for (let i = 0; i < item.amount; i++) {
      const seperatedItem = { ...item };
      seperatedItem.amount = 1;
      itemsSeperatedByAmount.push({ ...seperatedItem })
    }
  });

  return itemsSeperatedByAmount;
}

export function seperateItemsAndSort(items) {
  const itemsSeperatedByAmount = seperateItems(items);
  return sortItems(itemsSeperatedByAmount)
}
