import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Popup } from 'semantic-ui-react';
import { NotificationManager } from 'react-notifications';
import { Helmet } from 'react-helmet-async';

import {
  JackpotDepositModal,
  CountDownTimer,
  TradeOfferModal,
} from '../../components';
import JackpotRound from './JackpotRound';
import JackpotRoller from './JackpotRoller';
import JackpotRollDeposit from './JackpotRollDeposit';
import {
  getJackpotTotal,
  getJackpotStats,
  getSortedItems,
  getDepositChance,
} from '../../util/jackpot';
import {
  startJackpotRolling,
  endJackpotRolling,
  endJackpotRound,
  requestInventory,
  forceRefreshInventory,
  depositJackpotItems,
} from '../../actions';

import rollSound from '../../static/roll.mp3';

import './Jackpot.css';

import config from '../../config';

const { jackpot } = config;

const IMAGE_URL = 'https://steamcommunity-a.akamaihd.net/economy/image/';

class Jackpot extends Component {
  constructor(props) {
    super(props);

    this.openDepositModal = this.openDepositModal.bind(this);
    this.isRoundRolling = this.isRoundRolling.bind(this);
    this.stopRolling = this.stopRolling.bind(this);

    this.state = {
      depositModal: false,
      tradeOffer: {
        open: false,
        offer: null,
      },
      isRolling: false,
      rollingRound: null,
    };

    
  }
  componentWillMount() {
    this.props.secureSocket.on("JACKPOT_OFFER_ERROR", ({ error }) => {
      NotificationManager.error(`Error creating trade offer: ${error}`);
    });

    this.props.secureSocket.on("JACKPOT_OFFER", (offer) => {
      this.setState({
        tradeOffer: {
          open: true,
          offer: offer,
        },
      });
    });

    this.props.publicSocket.on("JACKPOT_END_ROUND", (round) => {
      this.props.endJackpotRound(round);
      this.startRollingAnimation(round);
    });
  }
  
  componentWillUnmount() {
    this.props.publicSocket.off("JACKPOT_END_ROUND");
    this.props.secureSocket.off("JACKPOT_OFFER");
    this.props.secureSocket.off("JACKPOT_OFFER_ERROR");
  }

  openDepositModal() {
    if (this.props.user && this.props.user.tradeUrl) {
      return this.setState({ depositModal: true });
    } else if (this.props.jackpot.depositing) {
      return NotificationManager.error("You are already depositing items");
    }
    NotificationManager.error(
      "Set your Tradelink in settings profile before deposit!"
    );
  }

  stopRolling() {
    this.setState({ isRolling: false, displayWinner: true });
    this.props.endJackpotRolling();
  }

  stopDisplayWinner() {
    this.setState({ displayWinner: false, rollingRound: null });
  }

  renderItems() {
    if (this.props.jackpot.loading) {
      return (
        <div className="JackpotItems"></div>
      );
    }

    const { currentRound } = this.props.jackpot;

    if (currentRound) {
      if (currentRound.deposits.length === 0) {
        return (
          <div className="JackpotItems">
            <span className="Info">No Deposits Yet</span>
          </div>
        );
      }
      const items = getSortedItems(currentRound);
      return (
        <div className="JackpotItems">
          {items.map((item, key) => (
            <Popup
              content={`${item.name} | ${Number(item.price).toFixed(2)}`}
              key={key}
              trigger={
                <div className="JackpotItem">
                  <img src={`${IMAGE_URL}${item.icon_url}`} alt="{item.name}" />
                </div>
              }
              position="top center"
              className="chatPopup"
            />
          ))}
        </div>
      );
    }
  }

  getColors() {
    const { currentRound } = this.props.jackpot;
    const deposits = currentRound.deposits;
    const colors = [
      "13E773",
      "137AE7",
      "7013E7",
      "E7D413",
      "E71359",
      "E75F13",
      "E713E4",
      "139BE7",
      "AFEA09",
      "A44CFC",
      "E47676",
      "30E5AF",
      "4F69AC",
    ];

    let sortedColors = []; //Sorted colors for players

    for (let key in deposits) {
      function checkId(userId) {
        return userId.id === deposits[key].id;
      }
      let alreadyIndex = deposits.findIndex(checkId);
      if (alreadyIndex === -1) {
        sortedColors.push(colors[key]);
      } else {
        sortedColors.push(colors[alreadyIndex]);
      }
    }

    return sortedColors;
  }

  renderHeaderBar() {
    if (this.props.jackpot.loading) {
      return null;
    }

    const { currentRound } = this.props.jackpot;

    if (this.state.displayWinner) {
      // const winnerChance = getWinnerChance(this.state.rollingRound);
      // const total = getJackpotTotal(this.state.rollingRound);
      const { winner } = this.state.rollingRound;
      setTimeout(() => {
        this.stopDisplayWinner();
      }, 8 * 1000); /* display the winner for 8 seconds */
      return (
        <div className="RollOverflow">
            <div className="RollBox">
              <span className="WinnerInfo">Winner is {winner.name}</span>
            </div>
          </div>
      );
    } else if (this.state.isRolling) {
      return (
        <JackpotRoller
          round={this.state.rollingRound}
          onComplete={this.stopRolling}
        />
      );
    } else if (currentRound) {
      if (currentRound.deposits.length === 0) {
        return (
          <div className="RollOverflow">
            <div className="RollBox">
            </div>
          </div>
        );
      }
      const deposits = currentRound.deposits;
      const sortedColors = this.getColors();
      return (
        <div className="RollOverflow">
          <div className="RollBox">
            <div className="RollItems">
              <div className="RollItem">
                <div className="PlayerItems" style={{ width: `100%` }}>
                  {deposits.map((deposit, key) => (
                    <JackpotRollDeposit
                      key={key}
                      deposit={deposit}
                      color={sortedColors[key]}
                      chances={getDepositChance(currentRound, deposit)}
                    ></JackpotRollDeposit>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <p className="Error">ERROR - please refresh</p>;
  }

  startRollingAnimation(round) {
    this.props.startJackpotRolling(round._id);
    this.setState({
      isRolling: true,
      rollingRound: round,
      displayWinner: false,
    });
    new Audio(rollSound).play();
  }

  jackpotTimer() {
    const { currentRound } = this.props.jackpot;
    const { gameCountdown } = jackpot.countdowns;
    let countDown = (
      <CountDownTimer
        game={"jackpot"}
        secondsRemaining={gameCountdown}
        totalSeconds={gameCountdown}
        freeze
      />
    );
    if (currentRound && currentRound.timerStart) {
      let secondsElapsed = parseInt(
        (new Date().getTime() - new Date(currentRound.timerStart).getTime()) /
          1000
      );
      if (secondsElapsed > gameCountdown) {
        secondsElapsed = gameCountdown;
      }
      countDown = (
        <CountDownTimer
          game={"jackpot"}
          secondsRemaining={gameCountdown - secondsElapsed}
          totalSeconds={gameCountdown}
        />
      );
    }

    return countDown
  }

  renderJackpotStats(jackpotTotal, items) {
    return (
      <div className="JackpotStats">
        <div className="Stat"><i className="fas fa-inbox"></i>{items}</div>
        <div className="Stat">${jackpotTotal}</div>
      </div>);
  }

  isRoundRolling(roundId) {
    return this.props.jackpot.rollingRound === roundId;
  }

  renderGames() {
    if (this.props.jackpot.loading) {
      return (
        <div className="Placeholder">
          
        </div>
      );
    }
    let currentRound = null;
    if (this.props.jackpot.currentRound && !this.props.jackpot.rollingRound) {
      currentRound = <JackpotRound round={this.props.jackpot.currentRound}/>;
    }
    return (
      <div className="JackpotRounds">
        {currentRound}
        {this.props.jackpot.historyRounds.map((round, key) => (
          <JackpotRound
            round={round}
            key={key}
            isRoundRolling={this.isRoundRolling}
          />
        ))}
      </div>
    );
  }

  getStats() {
    if (this.props.jackpot.currentRound) {
      return getJackpotStats(this.props.jackpot.currentRound, this.props.user);
    }
    return ["0.00", 0, "0.00", "0.00%", `0/${jackpot.game.maxItems}`];
  }

  render() {
    const [
      jackpotTotal,
      currentPlayers,
      numDeposited,
      chanceToWin,
      items,
    ] = this.getStats();

    return (
      <div className="Jackpot">
        <Helmet>
          <title>${jackpotTotal} | {config.metadata.name} Jackpot</title>
          <meta name="description" content="Jackpot for RUST skins"/>
        </Helmet>
        <TradeOfferModal
          isOpen={this.state.tradeOffer.open}
          onClose={() =>
            this.setState({ tradeOffer: { open: false, offer: null } })
          }
          tradeOffer={this.state.tradeOffer.offer}
        />
        <JackpotDepositModal
          isOpen={this.state.depositModal}
          onClose={() => this.setState({ depositModal: false })}
          inventory={this.props.inventory}
          loadInventory={this.props.requestInventory}
          forceRefreshInventory={this.props.forceRefreshInventory}
          round={this.props.jackpot.currentRound}
          depositItems={this.props.depositJackpotItems}
        />

        <div className="JackpotPanel">
          <div className="UserDepositInfo">
            <span>Deposited: ${numDeposited}</span>
            <span>Chances: {chanceToWin}</span>
          </div>
          <button className="Deposit" onClick={this.openDepositModal}>
            Deposit
          </button>
        </div>

        <div className="JackpotCurrentGame">
          {this.renderJackpotStats(jackpotTotal, items)}
          <div className="ProgressBox">{this.renderHeaderBar()}</div>
          {this.jackpotTimer()}
        </div>

        {this.renderGames()}
        {/* {this.renderItems()} */}
        
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inventory: state.user.inventory,
    user: state.auth.user,
    jackpot: state.jackpot,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      endJackpotRolling,
      endJackpotRound,
      requestInventory,
      forceRefreshInventory,
      depositJackpotItems,
      startJackpotRolling,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Jackpot);
