import React, { Component } from "react";
import Modal from "react-modal";
import config from "../../../config";
import { Popup } from "semantic-ui-react";
import CoinflipInventoryItem from "../../../containers/Coinflip/CoinflipInventoryItem";
import { NotificationManager } from "react-notifications";
import "./CoinflipCreateModal.css";
import black from "../../../static/bluecoin.svg";
import red from "../../../static/redcoin.svg";
// import { seperateItemsAndSort } from '../../../util/inventory'

const IMAGE_URL = "https://steamcommunity-a.akamaihd.net/economy/image/";

const { minItems, maxItems, minAmount, itemThreshold } = config.coinflip;

export default class CoinflipCreateModal extends Component {
  constructor(props) {
    super(props);

    this.requestClose = this.requestClose.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.submitGame = this.submitGame.bind(this);
    this.renderItemSelection = this.renderItemSelection.bind(this);
    this.renderCoinSelection = this.renderCoinSelection.bind(this);
    this.renderItems = this.renderItems.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.getTotalSelectedPrice = this.getTotalSelectedPrice.bind(this);
    this.getTotalInventoryPrice = this.getTotalInventoryPrice.bind(this);
    this.getSelectedCount = this.getSelectedCount.bind(this);
    this.clearItems = this.clearItems.bind(this);
    this.getSelectedItems = this.getSelectedItems.bind(this);

    this.state = {
      selected: "black",
      created: false,
      selectedItems: [],
    };
  }

  requestClose() {
    this.props.onClose();
    setTimeout(() => {
      this.setState({
        selected: "black",
        created: false,
        selectedItems: [],
      });
    }, 300);
  }

  renderModal() {
    if (this.state.created) {
      return this.renderItemSelection();
    }
    return this.renderCoinSelection();
  }

  nextSlide() {
    this.setState({
      created: true,
    });
    this.props.loadInventory();
  }

  renderCoinSelection() {
    const blackCoin = (
      <div
        className={`SelectCoin ${
          this.state.selected === "black" ? "selected" : ""
        }`}
        onClick={() => this.setState({ selected: "black" })}
      >
        <img src={black} alt="0 - 49%" ref="black" />
        <p>0 - 49%</p>
        {this.state.selected === "black" ? <i className="fas fa-check" /> : ``}
      </div>
    );
    const redCoin = (
      <div
        className={`SelectCoin ${
          this.state.selected === "red" ? "selected" : ""
        }`}
        onClick={() => this.setState({ selected: "red" })}
      >
        <img src={red} alt="50 - 100%" ref="red" />
        <p>50 - 100%</p>
        {this.state.selected === "red" ? <i className="fas fa-check" /> : ``}
      </div>
    );

    return (
      <div className="ModalCreateBox">
        <div className="ModalHeader">
          <span>Create coinflip | Select Coin</span>
        </div>
        <div className="ModalContent">
          <div className="CoinflipCreateSelectCoins">
            <Popup
              trigger={blackCoin}
              content="Select 0% - 49%"
              mouseEnterDelay={300}
              position="top center"
              className="chatPopup"
            />
            <Popup
              trigger={redCoin}
              content="Select 50% - 100%"
              mouseEnterDelay={300}
              position="top center"
              className="chatPopup"
            />
          </div>
        </div>
        <div className="ModalFooter ModalFooterDeposit">
          <div className="FooterInfo">
            <p>Click "Next" to continue...</p>
          </div>
          <div className="FooterButtons">
            <button onClick={this.requestClose}>Cancel</button>
            <button className="Accept" onClick={this.nextSlide}>
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }

  submitGame() {
    const betValue = this.getTotalSelectedPrice(),
      itemsSelected = this.getSelectedCount();
    if (this.state.selected == null) {
      return NotificationManager.error("Please retry your game creation");
    } else if (betValue < minAmount) {
      return NotificationManager.error(`Bet must be at least $${minAmount}`);
    } else if (itemsSelected < minItems || itemsSelected > maxItems) {
      return NotificationManager.error(
        `You must select between ${minItems} and ${maxItems} items`
      );
    }
    this.requestClose();
    this.props.createGame({
      side: this.state.selected,
      items: this.getSelectedItems(),
      timeout: this.refs.autoCancel.checked,
    });
  }

  renderItemSelection() {
    const betValue = this.getTotalSelectedPrice(),
      itemsSelected = this.getSelectedCount();
    const betClass = betValue >= minAmount ? "good" : "bad";
    const itemsClass = itemsSelected >= minItems && itemsSelected <= maxItems ? "good" : "bad";
    const buttonState =
      betValue >= minAmount &&
      itemsSelected >= minItems &&
      itemsSelected <= maxItems
        ? false
        : true;
    return (
      <div className="ModalCreateBox">
        <div className="ModalHeader">
          <span className="Title">Create coinflip | Select Items</span>
          <div className="UserInventoryInfo">
            <span title="Inventory Value">
              <i className="fas fa-dollar-sign"></i>
              {this.getTotalInventoryPrice()}
            </span>
            <span title="Inventory Items">
              <i className="fas fa-briefcase"></i>
              {this.getInventoryItemsCount()}
            </span>
          </div>
        </div>
        <div className="ModalContent">
          <div className="DepositPanel">
            <div className="RequiedValues">
              <span>Min bet: ${minAmount}</span>
              <span>Max items: {maxItems}</span>
            </div>
            <span className="CancelCheck">
              <label className="checkbox">
                <input type="checkbox" ref="autoCancel" onClick={true} />
                <span className="checkbox-check"></span>
              </label>
              <span>Cancel after 30 minutes</span>
            </span>
            <button
              className="DepositRefresh"
              onClick={this.props.forceRefreshInventory}
            >
              <i className="fas fa-sync-alt" />
            </button>
          </div>

          <div className="ItemsBox">
            <div className="ItemsWrapper">{this.renderItems()}</div>
          </div>
        </div>
        <div className="ModalFooter ModalFooterDeposit">
          <div className="FooterInfo">
            <div className="DepositValue">
              <span>Bet Value</span>
              <i className={`fas fa-dollar-sign ${betClass}`}></i>
              <span className="Value">{this.getTotalSelectedPrice()}</span>
            </div>
            <div className="DepositValue">
              <span>Selected items</span>
              <i className={`fas fa-inbox ${itemsClass}`}></i>
              <span className="Value">{`${itemsSelected}/${maxItems}`}</span>
            </div>
          </div>
          <div className="FooterButtons">
            <button onClick={this.requestClose}>Cancel</button>
            <button
              className="Accept"
              disabled={buttonState}
              onClick={this.submitGame}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    );
  }

  clearItems() {
    this.setState({
      selectedItems: [],
    });
  }

  renderItems() {
    const { items, error, loading } = this.props.inventory;
    if (loading) {
      return (
        <span className="InventoryLoader">
          <div className="sk-chase">
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
          </div>
        </span>
      );
    } else if (error && items.length === 0) {
      return (
        <div className="InventoryLoadError">
          <span>
            An error ocurred loading your inventory. You may not have any
            tradeable {config.metadata.gameName} items or Steam is offline.
          </span>
        </div>
      );
    }

    return items.map((item, key) => (
      <CoinflipInventoryItem
        name={item.name}
        image={`${IMAGE_URL}${item.icon_url}`}
        selected={this.isSelected(key)}
        price={item.price}
        key={key}
        disabled={item.price < itemThreshold}
        unselect={() => this.unselectItem(key)}
        select={() => this.selectItem(key)}
      />
    ));
  }

  isSelected(key) {
    for (var index in this.state.selectedItems) {
      if (this.state.selectedItems[index] === key) {
        return true;
      }
    }
    return false;
  }

  selectItem(index) {
    this.setState({
      selectedItems: this.state.selectedItems.concat([index]),
    });
  }

  unselectItem(index) {
    this.setState({
      selectedItems: this.state.selectedItems.filter((el) => el !== index),
    });
  }

  getSelectedCount() {
    return this.state.selectedItems.length;
  }

  getSelectedItems() {
    const array = [];
    for (var index in this.state.selectedItems) {
      array.push(this.props.inventory.items[this.state.selectedItems[index]]);
    }
    return array;
  }

  getTotalSelectedPrice() {
    let total = 0.0;
    for (var index in this.state.selectedItems) {
      total += Number(
        this.props.inventory.items[this.state.selectedItems[index]].price
      );
    }
    return total.toFixed(2);
  }

  getInventoryItemsCount() {
    let itemsCount = this.props.inventory.items.length;
    return itemsCount;
  }

  getTotalInventoryPrice() {
    let total = 0.0;
    for (var index in this.props.inventory.items) {
      const item = this.props.inventory.items[index];
      if (item.amount) {
        total += parseFloat(item.price * item.amount);
      }
    }
    return Number(total).toFixed(2);
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.requestClose}
        closeTimeoutMS={200}
        contentLabel="Modal"
        className={
          "Modal ModalCreateCoinflip " +
          (this.state.created ? "ModalCreateCoinflipCreated" : "")
        }
        overlayClassName={"Modal__Overlay"}
      >
        {this.renderModal()}
      </Modal>
    );
  }
}
