import React, { Component } from "react";
import { Helmet } from "react-helmet-async";

import config from "../config";

class FAQ extends Component {
  render() {
    const description = `${config.metadata.name} - Frequently Asked Questions`
    return (
      <div className="container">
        <Helmet>
          <title>FAQ - {config.metadata.name}</title>
          <meta name="description" content={description}/>
        </Helmet>
        <h4>Support</h4>
        <p>You can get refunds / offers resent from flips that didn't send a winning offer because of any bot / software / network problem.</p>
        <p>Join our Discord and open a support ticket in the support channel.</p>      
        <p>When contacting support make sure to give a detailed description of your question/problem.</p>
        <p>Sending fake information or asking for refunds you already recieved may result in a ban from the site.</p>
        <p>Your ticket will be reviewed and resolved as soon as possible. Thank you for your patience!</p>
        
        <h4><span style={{textDecoration: "none"}}><strong>Frequently Asked Questions</strong></span></h4>
        <h4><strong>Jackpot</strong></h4>
        <p>Jackpot is a game mode where users deposit their skins into a pot and after countdown time passes a winner is picked. Your chance of winning depends on the amount you deposited and the size of the pot. Big deposits in relation to the pot size will result in a bigger chance of winning.</p>
        <h4><strong>Coinflip</strong></h4>
        <p>Coinflip is a game mode where two users will play against each other, depositing similar quantities resulting in similar chances to win. A coin will be flipped and the winner will get both player's items.</p>
        <h4><strong>7% or 10% Tax</strong></h4>
        <p>If you didn't get all the items from a jackpot or coinflip game, that's because of the site's tax. Default tax is set to around { config.tax.noPromo * 100 -2}% or lower, but this can be reduced to { config.tax.promo * 100 -5}% by adding { config.metadata.url } to your steam name and relogging on the site.</p>
        <h4><strong>Provably fair</strong></h4>
        <p>At every round start, a salt and a winning percentage is generated. The salt and winning percentage is encrypted into a hash string that is displayed to every user. Once the round is completed, the salt and the winning percentage is revealed to all users. Users can take the given salt and winning percentage and re-encrypt with an SHA256 hash to validate the round. Alternatively, use the "verify" button on coinflip to validate hashes.
        <br></br><a href="https://jsfiddle.net/9gdfsv72/" target="_blank">Code editor for verifying rounds.</a></p>
        <h4><strong>Sponsor</strong></h4>
        <p>If you think your account is elegible to be sponsored (lots of followers on Youtube or other social media), join our discord and open a support ticket. Your request will be looked over and responded to.</p>
        <h4>How to level up</h4>
        <p>
          For each 0,01$ wagered, you will earn 1 XP. Every level has their
          total XP requirement. The total XP requirement increases with level size.
        </p>
        <h4>TF2.BET Mod/Admin added me on Steam?</h4>
        <p>
          Staff will have a rank in chat.
        </p>
        <h4>Didn't find an answer to your question?</h4>
        <p>
          If you didn't find an answer to your question, feel free to contact us via discord.
        </p>
      </div>
    );
  }
}

export default FAQ;
