import React, { Component } from 'react'
import CoinflipGameItem from './CoinflipGameItem'
import { CountDownTimer } from '../../components'
import { didCreatorWin } from '../../util/coinflip'

const black = "Blue";
const red = "Red";

const IMAGE_URL = 'https://steamcommunity-a.akamaihd.net/economy/image/'

const WAITING_COUNTDOWN = 120
const COMPLETION_COUNTDOWN = 10

export default class CoinflipGame extends Component {

  renderItems() {
    const sorted = this.sortedItems();
    if (sorted.length > 3) {
      const items = sorted
        .slice(0, 3)
        .map((item, key) => (
          <CoinflipGameItem
            key={key}
            name={item.name}
            image={`${IMAGE_URL}${item.icon_url}`}
            price={item.price}
          />
        ));
      return (
        <div className="CoinflipItems">
          {items}
          <span>×{sorted.length}</span>
        </div>
      );
    }
    const items = sorted
        .map((item, key) => (
          <CoinflipGameItem
            key={key}
            name={item.name}
            image={`${IMAGE_URL}${item.icon_url}`}
            price={item.price}
          />
        ));
    return(
      <div className="CoinflipItems">
          {items}
        </div>
    );
  }

  sortedItems() {
    return [...this.props.game.creator.items, ...this.props.game.joiner.items].sort((a, b) => {
      return b.price - a.price
    })
  }

  getTotalValue() {
    let total = 0.00
    for (const index in this.props.game.creator.items) {
      const item = this.props.game.creator.items[index]
      if (item && item.price) {
        total += Number(item.price)
      }
    }
    if (this.props.game.joiner && this.props.game.joiner.items) {
      for (const index in this.props.game.joiner.items) {
        const item = this.props.game.joiner.items[index]
        if (item && item.price) {
          total += Number(item.price)
        }
      }
    }
    return parseFloat(Number(total).toFixed(2))
  }

  getRange(totalValue) {
    const range = (totalValue * 0.1)
    return [Number(totalValue - range).toFixed(2), Number(totalValue + range).toFixed(2)]
  }

  getNumberData() {
    const totalValue = this.getTotalValue()
    const [low, high] = this.getRange(totalValue)
    return [parseFloat(Number(totalValue).toFixed(2)), low, high]
  }

  getStartingSide() {
    if (this.props.game.startingSide === 'red') {
      return red
    }
    return black
  }

  getSecondPlayerSide() {
    if (this.props.game.startingSide === 'red') {
      return black
    }
    return red
  }

  getImages() {
    const { game } = this.props
    if (didCreatorWin(game)) {
      return [game.creator.image, game.joiner.image, (game.startingSide === 'black' ? black : red), game.joiner.image, game.creator.image, 'tails']
    } else {
      return [game.joiner.image, game.creator.image, (game.startingSide === 'black' ? red : black), game.joiner.image, game.creator.image, 'heads']
    }
  }

  getStatus() { //game.dateCompleted, game.dateJoined
    const { game } = this.props
    if (game.completed) {
      const { dateCompleted } = game
      const secondsSinceCompleted = parseInt((new Date().getTime() - new Date(dateCompleted).getTime()) / 1000)

      if (secondsSinceCompleted > COMPLETION_COUNTDOWN) {
        const [winner, loser, side, joiner, creator, animationSide] = this.getImages()
        if(animationSide === 'heads') {
          return (
            <div className="CoinflipStatus {side}">
              <div className="coinflip-animation heads winner_animation"><div className="side-a"><span className="Red" style={{backgroundImage:"url("+joiner+")"}}></span></div><div className="side-b"><span className="Blue" style={{backgroundImage:"url("+creator+")"}}></span></div></div><img src={winner} className="Winner" alt="winner" />
          </div>
          )
        }
        return (
          <div className="CoinflipStatus {side}">
            <div className="coinflip-animation tails winner_animation"><div className="side-a"><span className="Red" style={{backgroundImage:"url("+joiner+")"}}></span></div><div className="side-b"><span className="Blue" style={{backgroundImage:"url("+creator+")"}}></span></div></div><img src={winner} className="Winner" alt="winner" />
          </div>
        );
      }

    return (
      <div className="CoinflipStatus">
        <span></span>
        <CountDownTimer
          secondsRemaining={COMPLETION_COUNTDOWN - secondsSinceCompleted}
          totalSeconds={COMPLETION_COUNTDOWN}
          color="#00c73d"
          onComplete={() => {
            this.forceUpdate()
          }}
        />
        <span></span>
      </div>
    )
    
    } else if (game.joiner.id) {
      const { dateJoined } = game
      let secondsSinceJoined = parseInt((new Date().getTime() - new Date(dateJoined).getTime()) / 1000)

      if (secondsSinceJoined > WAITING_COUNTDOWN) {
        secondsSinceJoined = WAITING_COUNTDOWN //hopefully this never happens ;)
      }
      return (
        <div className="CoinflipStatus">
            <CountDownTimer
            secondsRemaining={WAITING_COUNTDOWN - secondsSinceJoined}
            totalSeconds={WAITING_COUNTDOWN}
            color="#ffc900"
          />
        </div>
      )
    } else {
      return (
        <div className="CoinflipStatus">
          <span className="Open">Open</span>
        </div>
      )
    }
  }

  getPlayers() {
    const { creator, joiner } = this.props.game

    return (
      <div className="CoinflipPlayers">
          <a href={`https://steamcommunity.com/profiles/${creator.id}`} target="_blank" rel="noopener noreferrer" >
            <div className={`Player ${this.getStartingSide()}`}><img src={creator.image} alt="Creator" /></div>
          </a>
          <span>vs</span>
        {joiner.image ? (
            <a href={`https://steamcommunity.com/profiles/${joiner.id}`} target="_blank" rel="noopener noreferrer" >
              <div className={`Player ${this.getSecondPlayerSide()}`}><img src={joiner.image} alt="Joiner" /></div>
            </a>
          ) : (
            <div className={`Anonymous ${this.getStartingSide()}`}><i className="fas fa-ellipsis-h"></i></div>
          )}
      </div>
    )
  }

  render() {
    const [totalValue, lowMargin, highMargin] = this.getNumberData()
    return (

      <div className="CoinflipGame">
      { this.getPlayers() }
      { this.renderItems()}
      <div className="CoinflipValue notranslate">
        <span className="Value">{`$${totalValue}`}</span>
        <span className="Range">{`$${lowMargin} - $${highMargin}`}</span>
      </div>
      {this.getStatus()}
      <div className="CoinflipOptions">
        {!this.props.game.joiner.id &&
          <button className="Join notranslate" onClick={this.props.onJoin}>Join</button>
        }
        <button onClick={this.props.onWatch}><i className="fa fa-eye"></i></button>
      </div>
    </div>
    )
  }

}
