import React from 'react'
import ReactDOM from 'react-dom'
import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import configureStore from './store/configureStore'
import config from './config'
import { App } from './containers'
import { publicSocket, secureSocket } from './util/sockets'

import './components/index.css'
 
//== FILTER React / REDUX Console errors - componentWillMount / ....
try{
const backup = console.error; console.error = function filterWarnings(msg) {
  const supressedWarnings = ['warning text examples', 'componentWillMount(', 'router', '']; if (!supressedWarnings.some(entry =>msg&& msg.toString().includes(entry))) backup.apply(console, arguments);
};
const backup1 = console.log; console.log = function filterWarnings(msg) {
  const supressedWarnings = ['prev state','action','next state']; if (!supressedWarnings.some(entry =>msg&& msg.toString().includes(entry))) backup1.apply(console, arguments);
};
const backup2 = console.group; console.group = function filterWarnings(msg) {
  const supressedWarnings = ['action ']; if (!supressedWarnings.some(entry =>msg&& msg.toString().includes(entry))) backup2.apply(console, arguments);
}; }catch(e){}

const initialState = window.__INITIAL_STATE__
const app = document.getElementById('root')
const history = createBrowserHistory()
const store = configureStore(initialState, history, [
  {
    param: config.socket.public.param,
    socket: publicSocket
  }, {
    param: config.socket.secure.param,
    socket: secureSocket
  }
])

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <HelmetProvider>
        <App publicSocket={publicSocket} secureSocket={secureSocket} />
      </HelmetProvider>
    </ConnectedRouter>
  </Provider>,
  app
)