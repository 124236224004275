import React, { Component } from 'react'
import { Popup } from 'semantic-ui-react'
import { ranks } from '../../constants'

import './Message.css'

class Message extends Component {

  getUserLevel() {
    const { user } = this.props
    if (user.rank === 3) {
      return ("Dev")
    } else if (user.rank === 2) {
      return ("Admin")
    } else if (user.rank === 1) {
      return ("Mod")
    } else {
      return (user.level)
    }
  }

  getHeaderClass() {
    const { rank, isBot } = this.props.user
    if (isBot) {
      return 'bot'
    }
    if (rank > 0) {
      return ranks[rank]
    }
    return ''
  }

  render() {
    const { user, message, viewer, messageId } = this.props

    let name = user.name.replace(/[A-Za-z0-9]+\.[A-Za-z]+/g, '')
    try{ if(!viewer||viewer.name!==name)name=(name.slice(0,3)+name.slice(6, -4)).replace(" ",""); } catch(e){} /*remove first and last character from name*/
    const messageObject = (
      <div className={`Message ${this.getHeaderClass()}`}>
        <div className="MessageHeader">
          <img src={user.image} alt="user" />
          <span className={`MessageHeaderLevel Level-${this.getUserLevel()}`}>{ this.getUserLevel() }</span>
          <a target="_blank" rel="noopener noreferrer" href={`https://steamcommunity.com/profiles/${user._id}`}>
            <span>{name}</span>
          </a>
        </div>
        <div className="MessageContent" dangerouslySetInnerHTML={{__html: message}} />
      </div>
    )

    if (viewer && viewer.rank >= 1) {
      return (
          <Popup className="userActions" trigger={messageObject} on='click' position="top left">
            <Popup.Content>
              <button onClick={() => this.props.muteUser(user._id)}> Mute</button>
              <button onClick={() => this.props.banUser(user._id)}> Ban</button>
              <button onClick={() => this.props.deleteMessage(messageId)}> Delete</button>
            </Popup.Content>
          </Popup>
      )
    }
    return messageObject
  }

}

export default Message
