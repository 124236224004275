export const CREATE_COINFLIP_GAME         = Symbol('CREATE_COINFLIP_GAME')
export const CREATE_COINFLIP_GAME_SUCCESS = Symbol('CREATE_COINFLIP_GAME_SUCCESS')
export const CREATE_COINFLIP_GAME_FAILURE = Symbol('CREATE_COINFLIP_GAME_FAILURE')
export const COINFLIP_NEW_GAME            = Symbol('COINFLIP_NEW_GAME')
export const COINFLIP_REQUEST_OFFERS      = Symbol('COINFLIP_REQUEST_OFFERS')
export const COINFLIP_REQUEST_OFFERS_SUCCESS  = Symbol('COINFLIP_REQUEST_OFFERS_SUCCESS')
export const COINFLIP_REQUEST_OFFERS_FAILURE  = Symbol('COINFLIP_REQUEST_OFFERS_FAILURE')
export const COINFLIP_RESEND_OFFER            = Symbol('COINFLIP_RESEND_OFFER')
export const COINFLIP_RESEND_OFFER_SUCCESS    = Symbol('COINFLIP_RESEND_OFFER_SUCCESS')
export const COINFLIP_RESEND_OFFER_FAILURE    = Symbol('COINFLIP_RESEND_OFFER_FAILURE')
export const COINFLIP_CANCEL_OFFER            = Symbol('COINFLIP_CANCEL_OFFER')
export const COINFLIP_CANCEL_OFFER_SUCCESS    = Symbol('COINFLIP_CANCEL_OFFER_SUCCESS')
export const COINFLIP_CANCEL_OFFER_FAILURE    = Symbol('COINFLIP_CANCEL_OFFER_FAILURE')
export const COINFLIP_LOAD_GAMES              = Symbol('COINFLIP_LOAD_GAMES')
export const COINFLIP_LOAD_GAMES_SUCCESS      = Symbol('COINFLIP_LOAD_GAMES_SUCCESS')
export const COINFLIP_LOAD_GAMES_FAILURE      = Symbol('COINFLIP_LOAD_GAMES_FAILURE')
export const COINFLIP_JOIN_GAME               = Symbol('COINFLIP_JOIN_GAME')
export const COINFLIP_JOIN_GAME_SUCCESS       = Symbol('COINFLIP_JOIN_GAME_SUCCESS')
export const COINFLIP_JOIN_GAME_FAILURE       = Symbol('COINFLIP_JOIN_GAME_FAILURE')
export const COINFLIP_UPDATE_GAME             = Symbol('COINFLIP_UPDATE_GAME')
export const COINFLIP_REMOVE_GAME             = Symbol('COINFLIP_REMOVE_GAME')
export const COINFLIP_LOAD_STATS              = Symbol('COINFLIP_LOAD_STATS')
export const COINFLIP_LOAD_STATS_SUCCESS      = Symbol('COINFLIP_LOAD_STATS_SUCCESS')
export const COINFLIP_LOAD_STATS_FAILURE      = Symbol('COINFLIP_LOAD_STATS_FAILURE')
export const COINFLIP_SET_FLIPPED             = Symbol('COINFLIP_SET_FLIPPED')
export const COINFLIP_UPDATE_HISTORY          = Symbol('COINFLIP_UPDATE_HISTORY')
export const COINFLIP_LOAD_HISTORY            = Symbol('COINFLIP_LOAD_HISTORY')
export const COINFLIP_LOAD_HISTORY_SUCCESS    = Symbol('COINFLIP_LOAD_HISTORY_SUCCESS')
export const COINFLIP_LOAD_HISTORY_FAILURE    = Symbol('COINFLIP_LOAD_HISTORY_FAILURE')
export const COINFLIP_ADMIN_LOAD_OFFERS       = Symbol('COINFLIP_ADMIN_LOAD_OFFERS')
export const COINFLIP_ADMIN_LOAD_OFFERS_SUCCESS       = Symbol('COINFLIP_ADMIN_LOAD_OFFERS_SUCCESS')
export const COINFLIP_ADMIN_LOAD_OFFERS_FAILURE       = Symbol('COINFLIP_ADMIN_LOAD_OFFERS_FAILURE')
export const COINFLIP_ADMIN_RESEND_OFFER              = Symbol('COINFLIP_ADMIN_RESEND_OFFER')
export const COINFLIP_ADMIN_RESEND_OFFER_SUCCESS      = Symbol('COINFLIP_ADMIN_RESEND_OFFER_SUCCESS')
export const COINFLIP_ADMIN_RESEND_OFFER_FAILURE      = Symbol('COINFLIP_ADMIN_RESEND_OFFER_FAILURE')
