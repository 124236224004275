import React, { Component } from 'react';

export default class JackpotRollDeposit extends Component {
  getTotal(deposit) {
    let total = 0.0;
    for (const index in deposit.items) {
      total += parseFloat(deposit.items[index].price);
    }
    return total;
  }

  render() {
    const { color } = this.props;
    const { chances } = this.props;

    const userChances = chances * 100;
    return (
      <div
        className="PlayerItem"
        style={{
          backgroundColor: `#${color}`,
          width: `${userChances}%`,
        }}
      ></div>
    );
  }
}
