import React, { Component } from 'react'
import { Tab, Input, Dropdown } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Helmet } from 'react-helmet-async'

import HistoryTableCoinflip from './HistoryTableCoinflip'
import HistoryTableJackpot from './HistoryTableJackpot'
import config from '../../config'

import {
  historyLoadCoinflip,
  historyLoadJackpot
} from '../../actions'

import './History.css'

const sortOptions = [
  {
    key: 'date ascending',
    text: 'Latest',
    value: 'date ascending'
  },
  {
    key: 'date descending',
    text: 'Oldest',
    value: 'date descending'
  },
  {
    key: 'value descending',
    text: 'Highest Value',
    value: 'value descending'
  },
  {
    key: 'value ascending',
    text: 'Lowest Value',
    value: 'value ascending'
  }
]

class History extends Component {

  constructor(props) {
    super(props)

    this.renderJackpot = this.renderJackpot.bind(this)
    this.renderCoinflip = this.renderCoinflip.bind(this)
    this.updateJackpotSearch = this.updateJackpotSearch.bind(this)
    this.updateCoinflipSearch = this.updateCoinflipSearch.bind(this)

    this.panes = [
      { menuItem: 'Jackpot', render: this.renderJackpot },
      { menuItem: 'Coinflip', render: this.renderCoinflip }
    ]

    this.state = {
      coinflipFilter: 'date ascending',
      jackpotFilter: 'date ascending',
      coinflipSearch: null,
      jackpotSearch: null
    }
  }

  onDropdownChange(game, data) {
    if (game === 'coinflip') {
      this.setState({ coinflipFilter: data.value })
    } else {
      this.setState({ jackpotFilter: data.value })
    }
  }

  updateJackpotSearch(event) {
    if (event.key === 'Enter') {
      this.setState({ jackpotSearch: event.target.value })
    }
  }

  updateCoinflipSearch(event) {
    if (event.key === 'Enter') {
      this.setState({ coinflipSearch: event.target.value })
    }
  }

  renderJackpot() {
    const { jackpot } = this.props.history
    return (
      <Tab.Pane className="Pane">
        <HistoryTableJackpot data={jackpot.data} playerSearch={this.state.jackpotSearch} jackpot={jackpot} loadHistory={this.props.historyLoadJackpot} filter={this.state.jackpotFilter} />
      </Tab.Pane>
    )
  }

  renderCoinflip() {
    const { coinflip } = this.props.history
    return (
      <Tab.Pane className="Pane">
        <HistoryTableCoinflip playerSearch={this.state.coinflipSearch} data={coinflip.data} coinflip={coinflip} loadHistory={this.props.historyLoadCoinflip} filter={this.state.coinflipFilter}/>
      </Tab.Pane>
    )
  }

  render() {
    const description = `${config.metadata.name} games rounds history`
    return (
      <div className="container">
        <Helmet>
          <title>History - {config.metadata.name}</title>
          <meta name="description" content={description}/>
        </Helmet>
        <Tab menu={{ text: true, className: "HistoryMenu" }} panes={this.panes} className="History" />
      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    history: state.history
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    historyLoadJackpot,
    historyLoadCoinflip
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(History)
