import {
  REQUEST_INVENTORY,
  REQUEST_INVENTORY_SUCCESS,
  REQUEST_INVENTORY_FAILURE,
  SAVE_TRADE_URL_SUCCESS,
  SAVE_TRADE_URL_FAILURE,
  SAVE_TRADE_URL_REQUEST,
  AUTH_USER_RELOAD,
  FORCE_REQUEST_INVENTORY,
  GET_REFERRAL_DATA, 
  GET_REFERRAL_DATA_SUCCESS,
  GET_REFERRAL_DATA_FAILURE
} from '../constants'
import { seperateItemsAndSort } from '../util/inventory'

const initialState = {
  tradeUrlLoading: false,
  forceRefresh: false,
  inventory: {
    loading: false,
    loaded: false,
    error: null,
    items: []
  },
  referrals: {
    loading: false,
    loaded: false,
    depositorsCount: 0,
    depositorsNeeded: 0,
    feeFromRake: 0,
    level: 0,
    levels: [],
    referralsCount: 0,
    referralAvailableEarnings: 0,
    referralTotalEarnings: 0,
    referralWithdraws: [], 
    cacheTime: 0,
    minWithdraw: 0
  }
}

export default function reducer(state = initialState, {type, payload}) {
  switch (type) {
    case SAVE_TRADE_URL_REQUEST:
      return {
        ...state,
        tradeUrlLoading: true
      }
    case SAVE_TRADE_URL_SUCCESS:
      return {
        ...state,
        forceRefresh: true
      }
    case SAVE_TRADE_URL_FAILURE:
      return {
        ...state
      }
    case AUTH_USER_RELOAD:
      return {
        ...state,
        forceRefresh: false
      }
    case REQUEST_INVENTORY:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          loading: true
        }
      }
    case REQUEST_INVENTORY_SUCCESS:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          loaded: true,
          loading: false,
          error: null,
          items: seperateItemsAndSort(payload)
        }
      }
    case REQUEST_INVENTORY_FAILURE:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          loaded: false,
          loading: false,
          error: payload
        }
      }
    case FORCE_REQUEST_INVENTORY:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          loading: true
        }
      }
      case GET_REFERRAL_DATA_SUCCESS:
        return {
          ...state,
          referrals: {
            loading: false,
            loaded: true,
            depositorsCount: payload.depositorsCount,
            depositorsNeeded: payload.depositorsNeeded,
            feeFromRake: payload.feeFromRake,
            level: payload.level,
            levels: payload.config.levels,
            referralsCount: payload.referralsCount,
            referralAvailableEarnings: payload.availableEarnings,
            referralTotalEarnings: payload.totalEarnings,
            referralWithdraws: payload.withdraws,
            cacheTime: payload.config.userDataCacheTime,
            minWithdraw: payload.config.minWithdraw
          }
        }
      case GET_REFERRAL_DATA_FAILURE:
        return {
          ...state,
          referrals: {
            ...state.referrals,
            loading: false,
            loaded: false
          }
        }
      case GET_REFERRAL_DATA:
        return {
          ...state,
          referrals: {
            ...state.referrals,
            loading: true
          }
        }
    default:
      return state
  }
}
