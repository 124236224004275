import React, { Component } from 'react';
import { Menu, Popup } from 'semantic-ui-react';

const ITEMS_PER_PAGE = 10;

class ReferralsWithdrawTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
    };
  }

  get maxPages() {
    const { withdrawHistory } = this.props;
    if (!withdrawHistory || withdrawHistory.length === 0) {
      return 0;
    }

    return Math.ceil(withdrawHistory.length / ITEMS_PER_PAGE);
  }

  setCurrentPage(pageNumber) {
    if (pageNumber === 0) {
      pageNumber = 1;
    } else if (pageNumber > this.maxPages) {
      pageNumber = this.maxPages;
    }
    this.setState({ currentPage: pageNumber });
  }

  renderPaginationItems() {
    const maxPages = this.maxPages;
    if (maxPages === 0) {
      return (
        <Menu floated="right" pagination className="HistoryPagination">
          <Menu.Item as="a">
            <i className="fas fa-chevron-left"></i>
          </Menu.Item>
          <Menu.Item as="a">0</Menu.Item>
          <Menu.Item as="a">
            <i className="fas fa-chevron-right"></i>
          </Menu.Item>
        </Menu>
      );
    }
    var { currentPage } = this.state;
    if (currentPage > maxPages) {
      currentPage = maxPages;
    }

    return (
      <Menu floated="right" pagination className="HistoryPagination">
        <Menu.Item as="a" onClick={() => this.setCurrentPage(1)}>
          <i className="fas fa-angle-double-left"></i>
        </Menu.Item>
        <Menu.Item as="a" onClick={() => this.setCurrentPage(currentPage - 1)}>
          <i className="fas fa-chevron-left"></i>
        </Menu.Item>
        {currentPage - 2 > 0 && (
          <Menu.Item
            as="a"
            onClick={() => this.setCurrentPage(currentPage - 2)}
          >
            {currentPage - 2}
          </Menu.Item>
        )}
        {currentPage - 1 > 0 && (
          <Menu.Item
            as="a"
            onClick={() => this.setCurrentPage(currentPage - 1)}
          >
            {currentPage - 1}
          </Menu.Item>
        )}
        <Menu.Item as="a" active>
          {currentPage}
        </Menu.Item>
        {currentPage + 1 <= maxPages && (
          <Menu.Item
            as="a"
            onClick={() => this.setCurrentPage(currentPage + 1)}
          >
            {currentPage + 1}
          </Menu.Item>
        )}
        {currentPage + 2 <= maxPages && (
          <Menu.Item
            as="a"
            onClick={() => this.setCurrentPage(currentPage + 2)}
          >
            {currentPage + 2}
          </Menu.Item>
        )}
        <Menu.Item as="a" onClick={() => this.setCurrentPage(currentPage + 1)}>
          <i className="fas fa-chevron-right"></i>
        </Menu.Item>
        <Menu.Item as="a" onClick={() => this.setCurrentPage(maxPages)}>
          <i className="fas fa-angle-double-right"></i>
        </Menu.Item>
      </Menu>
    );
  }

  renderWithdrawItem(item, index) {
    const dateCreated = new Date(item.dateCreated)
    return (
      <div className="ReferralWithdrawItem" key={index}>
        <div className="WithdrawDate">{dateCreated.toLocaleDateString()}</div>
        <div className="WithdrawId">{item._id}</div>
        <div className="WithdrawValue">${item.value.toFixed(2)}</div>
        <div className="WithdrawStatus">
          {!item.completed ? (
            <Popup
              className="chatPopup"
              trigger={<i className="fas fa-times error"></i>}
              content="Awaiting to be sent..."
            />
          ) : (
            <i className="fas fa-check success"></i>
          )}
        </div>
      </div>
    );
  }

  renderTable() {
    const { withdrawHistory } = this.props
    const { currentPage } = this.state
    if (!withdrawHistory) {
      return null
    }

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
    const selection = withdrawHistory.slice(startIndex, startIndex + ITEMS_PER_PAGE)

    return selection.map((item, index) => this.renderWithdrawItem(item, index))
  }

  render() {
    return (
      <div className="ReferralWithdrawTable">
        {this.renderPaginationItems()}
        <div className="ReferralWithdrawItem">
          <div className="WithdrawDate">Date</div>
          <div className="WithdrawId">Id</div>
          <div className="WithdrawValue">Value</div>
          <div className="WithdrawStatus">Status</div>
        </div>
        {this.renderTable()}

        {this.renderPaginationItems()}
      </div>
    );
  }
}

export default ReferralsWithdrawTable;
