import React, { Component } from 'react'
import { Menu, Popup } from 'semantic-ui-react'
import moment from 'moment'

const ITEMS_PER_PAGE = 2000
const IMAGE_URL = 'https://steamcommunity-a.akamaihd.net/economy/image/'

class AdminTradesTable extends Component {

  constructor(props) {
    super(props)

    this.state = {
      currentPage: 1
    }

    this.data = props.rake.data
  }

  componentWillMount() {
    const { rake, loadRake } = this.props
    if (!rake.loaded && !rake.loading) {
      loadRake()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.rake.data !== this.props.rake.data) {
      this.data = nextProps.rake.data
    }

    if (nextProps.filter !== this.props.filter) {
      this.sortData(nextProps.filter)
    } else if (nextProps.search && nextProps.search !== this.props.search) {
      this.sortBySearch(nextProps.search)
    }
  }

  sortBySearch(itemName) {
    this.data = this.props.rake.data.filter((item) => {
      if (item.name && ~item.name.toLowerCase().indexOf(itemName.toLowerCase())) {
        return true
      }
      return false
    })
    this.setState({ currentPage: 1 })
  }

  sortData(filter) {
    if (filter === 'date ascending') {
      this.data.sort((a, b) => {
        return new Date(b.dateAdded) - new Date(a.dateAdded)
      })
    } else if (filter === 'date descending') {
      this.data.sort((a, b) => {
        return new Date(a.dateAdded) - new Date(b.dateAdded)
      })
    } else if (filter === 'available') {
      this.data.sort((a, b) => {
        return a.withdrawn - b.withdrawn
      })
    } else if (filter === 'withdrawn') {
      this.data.sort((a, b) => {
        return b.withdrawn - a.withdrawn
      })
    }
    this.setState({ currentPage: 1 })
  }

  get maxPages() {
    const { data } = this
    if (!data || data.length === 0) {
      return 0
    }

    return Math.ceil(data.length / ITEMS_PER_PAGE)
  }

  setCurrentPage(pageNumber) {
    if (pageNumber === 0) {
      pageNumber = 1
    } else if (pageNumber > this.maxPages) {
      pageNumber = this.maxPages
    }
    this.setState({ currentPage: pageNumber })
  }

  renderPaginationItems() {
    const maxPages = this.maxPages;
    if (maxPages === 0) {
      return (
        <Menu floated="right" pagination className="HistoryPagination">
          <Menu.Item as="a">
            <i className="fas fa-chevron-left"></i>
          </Menu.Item>
          <Menu.Item as="a">0</Menu.Item>
          <Menu.Item as="a">
            <i className="fas fa-chevron-right"></i>
          </Menu.Item>
        </Menu>
      );
    }
    var { currentPage } = this.state
    if (currentPage > maxPages) {
      currentPage = maxPages
    }

    return (
      <Menu floated="right" pagination className="HistoryPagination">
        <Menu.Item as="a" onClick={() => this.setCurrentPage(1)}>
          <i className="fas fa-angle-double-left"></i>
        </Menu.Item>
        <Menu.Item as="a" onClick={() => this.setCurrentPage(currentPage - 1)}>
          <i className="fas fa-chevron-left"></i>
        </Menu.Item>
        {currentPage - 2 > 0 && (
          <Menu.Item
            as="a"
            onClick={() => this.setCurrentPage(currentPage - 2)}
          >
            {currentPage - 2}
          </Menu.Item>
        )}
        {currentPage - 1 > 0 && (
          <Menu.Item
            as="a"
            onClick={() => this.setCurrentPage(currentPage - 1)}
          >
            {currentPage - 1}
          </Menu.Item>
        )}
        <Menu.Item as="a" active>
          {currentPage}
        </Menu.Item>
        {currentPage + 1 <= maxPages && (
          <Menu.Item
            as="a"
            onClick={() => this.setCurrentPage(currentPage + 1)}
          >
            {currentPage + 1}
          </Menu.Item>
        )}
        {currentPage + 2 <= maxPages && (
          <Menu.Item
            as="a"
            onClick={() => this.setCurrentPage(currentPage + 2)}
          >
            {currentPage + 2}
          </Menu.Item>
        )}
        <Menu.Item as="a" onClick={() => this.setCurrentPage(currentPage + 1)}>
          <i className="fas fa-chevron-right"></i>
        </Menu.Item>
        <Menu.Item as="a" onClick={() => this.setCurrentPage(maxPages)}>
          <i className="fas fa-angle-double-right"></i>
        </Menu.Item>
      </Menu>
    );
  }

  renderItem(item) {
    return (
      <Popup
        className="chatPopup"
        trigger={<img className="AdminRakeItem" src={`${IMAGE_URL}${item.icon_url}`} alt={item.name} />}
        content={item.name}
      />
    )
  }

  renderWithdraw(item) {
    if (item.withdrawn) {
      return (
        <i className="fas fa-check"></i>
      )
    } else {
      return (
          <button className="AdminWithdraw" onClick={() => this.props.withdrawRake(item)}>
            Withdraw
          </button>
      )
    }
  }

  renderRakeItem(item, index) {
    const date = moment(new Date(item.dateAdded)).fromNow()
    return (
      <div className={`AdminRakeRow ${item.withdrawn ? 'withdrawed':''}`} key={index}>
        <div className="AdminRakeItemCell">
          {this.renderItem(item)}
        </div>
        <div className="AdminRakeItemPriceCell">
          ${item.price}
        </div>
        <div className="AdminRakeItemBotIdCell">
          <a href={`https://steamcommunity.com/profiles/${item.botId}/inventory/#252490_2_${item.assetid}`} target="_blank">{item.botId}</a>
        </div>
        <div className="AdminRakeDateCell">
          {date}
        </div>
        <div className="AdminRakeItemWithdrawCell">
          { this.renderWithdraw(item) }
        </div>
      </div>
    )
  }

  renderTable() {
    const { data } = this
    const { currentPage } = this.state
    if (!data) {
      return null
    }

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
    const selection = data.slice(startIndex, startIndex + ITEMS_PER_PAGE)

    return selection.map((item, index) => this.renderRakeItem(item, index))
  }

  render() {
    if (this.props.rake.loading) {
      return <div className="ui active centered huge inline loader"></div>
    }

    return (
      <>

        { this.renderPaginationItems() }

        { this.renderTable() }

        { this.renderPaginationItems() }
      </>
    )
  }

}

export default AdminTradesTable
