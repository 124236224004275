import React, { Component } from 'react'
import JackpotDeposit from './JackpotDeposit'
import { getTotalJackpotItems, getJackpotTotal, getDepositChance, getWinnerChance } from '../../util/jackpot'
import moment from 'moment'

export default class JackpotRound extends Component {

  constructor(props) {
    super(props)

    this.state = {
      titeExpanded: false,
      winnerExpanded: false
    }
  }

  renderGameTitle() {
    const { round } = this.props

    if(round.completed) {
      const items = getTotalJackpotItems(round), total = getJackpotTotal(round)
      const canShowHiddenInfo = !this.props.isRoundRolling(round._id)

      return (
        <div className={`RoundFooter ${this.state.titleExpanded ? 'Active' : ''}`}>
        <div className="SimpleInfo">
          <small>{`${moment(new Date(round.dateCreated)).fromNow()}`}</small>
          <span><span className="Info">Hash:</span> {round.hash}</span>
          <i className={`Extender fas ${this.state.titleExpanded ? 'fa-angle-down' : 'fa-angle-left'}`} onClick={() => this.setState({ titleExpanded: !this.state.titleExpanded })}></i>
        </div>
        <div className="ExtendedInfo">
          <p><span className="Info">Round:</span> #{round._id}</p>
          <p><span className="Info">Created:</span> {new Date(round.dateCreated).toLocaleTimeString()}</p>
          <p><span className="Info">Items:</span> {items}</p>
          <p><span className="Info">Value:</span> ${Number(total).toFixed(2)}</p>
          <p><span className="Info">Hash:</span> {round.hash}</p>
          {canShowHiddenInfo && 
            <p><span className="Info">Percent:</span> {round.winningPercentage}</p>
          }
          {canShowHiddenInfo && 
            <p><span className="Info">Salt:</span> {round.secret}</p>
          }
        </div>
      </div>
      )
    }
    return (
      <div className={`RoundFooter ${this.state.titleExpanded ? 'Active' : ''}`}>
        <div className="SimpleInfo">
          <span><span className="Info">Hash:</span> {round.hash}</span>
          <i className={`Extender fas ${this.state.titleExpanded ? 'fa-angle-down' : 'fa-angle-left'}`} onClick={() => this.setState({ titleExpanded: !this.state.titleExpanded })}></i>
        </div>
        <div className="ExtendedInfo">
          <p><span className="Info">Round:</span> #{round._id}</p>
          <p><span className="Info">Created:</span> {new Date(round.dateCreated).toLocaleTimeString()}</p>
          <p><span className="Info">Hash:</span> {round.hash}</p>
        </div>
      </div>
    )
  }


  getColors() {
    const deposits = [...this.props.round.deposits]
    const colors = [
      "13E773",
      "137AE7",
      "7013E7",
      "E7D413",
      "E71359",
      "E75F13",
      "E713E4",
      "139BE7",
      "AFEA09",
      "A44CFC",
      "E47676",
      "30E5AF",
      "4F69AC",
    ];
    
    let sortedColors = []; //Sorted colors for players

    for (let key in deposits) {

      function checkId(userId) {
        return userId.id === deposits[key].id
      }
      let alreadyIndex = deposits.findIndex(checkId)
      if(alreadyIndex === -1) {
        sortedColors.push(colors[key])
      } else {
        sortedColors.push(colors[alreadyIndex])
      }
    }

    return sortedColors
  }

  renderDeposits() {
    const { round } = this.props
    const deposits = [...this.props.round.deposits]
    const depositsColors = this.getColors()

    const reversedDeposits = deposits.slice(0).reverse()
    const reversedColors = depositsColors.slice(0).reverse()

    let roundEnded = true;
    if (round.completed && !this.props.isRoundRolling(round._id)) {
      roundEnded = false;
    }

    if(deposits.length === 0) {
      return <span className="NoDeposits"><i className="fas fa-dice-five fa-spin"></i>Waiting for players...</span>
    }
    return reversedDeposits.map((deposit, key) => (
      <JackpotDeposit key={key} deposit={deposit} color={reversedColors[key]} chances={getDepositChance(round, deposit)} ended={roundEnded} />
    ))
  }

  getWinnerColor(winnerId) {
    const deposits = [...this.props.round.deposits]
    const winnerIdForSearch = winnerId

    let winnerIndex;
    
    for (let key in deposits) {

      function checkId(userId) {
        return userId.id === winnerIdForSearch
      }
      let alreadyIndex = deposits.findIndex(checkId)
      if(alreadyIndex > -1) {
        winnerIndex = alreadyIndex
      }
    }

    return winnerIndex
  }

  renderWinner() {
    const { round } = this.props
    const depositsColors = this.getColors()
    const items = getTotalJackpotItems(round), total = getJackpotTotal(round)

    if (round.completed) {
      const { winner } = round
      const winnerId = winner.id
      const winnerColor = this.getWinnerColor(winnerId)
      
      const canShowWinner = !this.props.isRoundRolling(round._id)
      return (
        <div className="RoundWinner">
            {canShowWinner &&
              <div className="WinnerBox">
                <div className="Info">
                <a target="_blank" href={`https://steamcommunity.com/profiles/${winner.id}`} rel="noopener noreferrer">
                  <img src={winner.image} alt={winner.name}  style={{borderColor: `#${depositsColors[winnerColor]}`,boxShadow: `#${depositsColors[winnerColor]} 0 0 6px 0`}} />
                </a>
                  <span className="WinnerText"><span>{winner.name}</span> won <span>${Number(total).toFixed(2)}</span> in <span>{items} items</span> with <span>{getWinnerChance(round)}%</span></span>
                </div>
                
                <a target="_blank" href={`https://twitter.com/intent/tweet?text=Look! ${winner.name} just won round №${round._id} at @rustshufflesite! He won $${Number(total).toFixed(2)}!`} rel="noopener noreferrer">
                  <i className="fab fa-twitter"></i>
                </a>
              </div>
              }
        </div>
      )
    }
  return <div className="RoundWinner"></div>
  }

  render() {
    let showRound = this.props.canShowRound
    const { round } = this.props
    let roundEnded = true;
    if (round.completed && !this.props.isRoundRolling(round._id)) {
      roundEnded = false;
    }

    return (
      <div className="JackpotRound" style={{display: showRound ? `none` : ``}}>
        { this.renderWinner() }
        <div className={roundEnded ? `RoundDeposits`: `RoundDeposits Ended`}>
        { this.renderDeposits() }
        </div>
        <div className={roundEnded ? ``: `RoundDeposits`}> { roundEnded ? `` : this.renderDeposits() } </div>
        { this.renderGameTitle() }
      </div>
    )
  }

}
