export function formatWithdrawsFromUsers(users) {
  const withdraws = []

  users.forEach((user) => {
    const userWithoutReferralWithdraws = {...user}
    delete userWithoutReferralWithdraws['referralWithdraws']

    user.referralWithdraws.forEach((withdraw) => {
      withdraws.push({
        ...withdraw,
        user: userWithoutReferralWithdraws,
      })
    })
  })

  return withdraws
}

