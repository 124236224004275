import React, { Component } from 'react';
import CountUp from 'react-countup';

export default class GameAmountBadge extends Component {
  render() {
    const { value } = this.props
    
    return <CountUp className="Value notranslate" prefix={"$"} end={value} duration={.5} separator="," decimals={0} preserveValue={true} />
  }
}
