import React, { Component } from "react";
import Modal from "react-modal";
import {
  getUserTotal,
  getCoinflipTotal,
  didCreatorWin,
} from "../../../util/coinflip";
import { Popup } from 'semantic-ui-react';
import CountUp from "react-countup";

const black = "Black";
const red = "Red";

const IMAGE_URL = "https://steamcommunity-a.akamaihd.net/economy/image/";

class CoinflipWatchItem extends Component {
  render() {
    return (
      <Popup 
        className="chatPopup"
        size='mini'
        position="top center"
        content={this.props.item.name}
        trigger={
          <div className="Item">
            <img src={`${IMAGE_URL}${this.props.item.icon_url}`} alt="item" />
            <div className="ItemPrice">
              ${Number(this.props.item.price).toFixed(2)}
            </div>
          </div>
        }
      />
      
    );
  }
}

export default class CoinflipHistoryModal extends Component {
  renderGame() {
    const { game } = this.props;
    return (
      <div className="CoinflipBody">
        {this.renderUser(
          game.creator,
          game.startingSide === "black" ? black : red,
          this.getCreatorPercent(game),
          ""
        )}
        {this.renderUser(
          game.joiner,
          game.startingSide === "black" ? red : black,
          this.getJoinerPercent(game),
          ""
        )}
        <div className="CoinflipStatus">{this.getStatus()}</div>
        <span className="Divider"></span>
      </div>
    );
  }

  displayWinner(winningSide) {
    const { game } = this.props;
    const percentage = game.winningPercentage
    
    return (
      <div className="Flipper Flipped">
        <div
          className={`CoinFlip ${winningSide === "black" ? black : red}${percentage < 25 || percentage > 75 ? `-2`:``}`}
        ></div>
      </div>
    );
  }

  displayGameSecrets() {
    const { game } = this.props;
    return (
      <div className="CoinflipFooterContent">
        <div className="FooterCoinflipInfo">
          <span className="Hash">Hash: {game.hash}</span>
          <span className="Percent">Roll: {game.winningPercentage}</span>
          <span className="Secret">Secret: {game.secret}</span>
        </div>
        <button onClick={this.props.onClose}>Close</button>
      </div>
    );
  }

  getStatus() {
    const { game } = this.props;
    return this.displayWinner(
      didCreatorWin(game)
        ? game.startingSide
        : game.startingSide === "black"
        ? "red"
        : "black",
      game
    );
  }

  getCreatorPercent(game) {
    if (game.joiner.items.length === 0) {
      return "--";
    }
    const total = getCoinflipTotal(game);
    const creatorTotal = getUserTotal(game.creator);
    return Number((creatorTotal / total) * 100).toFixed(3);
  }

  getJoinerPercent(game) {
    if (game.joiner.items.length === 0) {
      return "--";
    }
    const total = getCoinflipTotal(game);
    const joinerTotal = getUserTotal(game.joiner);
    return Number((joinerTotal / total) * 100).toFixed(3);
  }

  renderUser(user, side, percent, resultClass) {
    if (!user.image) {
      return null;
    }
    return (
      <div className={`CoinflipPlayer ${resultClass} ${side}`}>
        <div className="CoinflipUser">
          <img src={user.image} alt={user.name} />
          <span className="UserName">{user.name}</span>
        </div>
        {this.renderItems(user, percent)}
      </div>
    );
  }

  renderItems(user, percent) {
    if (user.items.length === 0) {
      return <div className="Waiting">Waiting...</div>;
    }
    const items = this.sortItems(user.items).map((item, key) => (
      <CoinflipWatchItem item={item} key={key} />
    ));
    return (
      <div className="CoinflipUserPanel">
        <div className="UserPanelInfo">
          <CountUp className="Chances" start={0} end={percent} duration={1} decimals={3} suffix="%"/>
          <CountUp className="Value" start={0} end={getUserTotal(user)} duration={1} decimals={2} prefix="$"/>
        </div>
        <div className="UserPanelItems">{items}</div>
      </div>
    );
  }

  sortItems(items) {
    return items.sort((a, b) => b.price - a.price);
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onClose}
        closeTimeoutMS={200}
        onAfterOpen={this.afterOpen}
        contentLabel="Modal"
        className="Modal ModalCoinflipWatch"
        overlayClassName="Modal__Overlay"
      >
        <div className="ModalContent">
          {this.props.game && this.renderGame()}
        </div>
        <div className="ModalFooter">
          {this.props.game ? this.displayGameSecrets() : ""}
        </div>
      </Modal>
    );
  }
}
