import React, { Component } from "react";
import { Popup } from "semantic-ui-react";

const failureReasons = {
  "1": "Invalid",
  "2": "Active",
  "3": "Accepted",
  "4": "Countered",
  "5": "Expired",
  "6": "Canceled",
  "7": "Declined",
  "8": "InvalidItems",
  "9": "CreatedNeedsConfirmation",
  "10": "CanceledBySecondFactor",
  "11": "InEscrow",
};

export default class CoinflipOfferRow extends Component {
  getStatus() {
    const { offer } = this.props;
    if (offer.completed === true) {
      return (
        <span className="Success">
          <i className="fas fa-check"></i>
        </span>
      );
    } else if (offer.failed === true) {
      return (
        <Popup
          trigger={
            <span className="Fail">
              <i className="fas fa-times"></i>
            </span>
          }
          content={failureReasons[offer.failureReason]}
          position="top center"
          className="chatPopup"
        />
      );
    } else {
      return (
        <span>
          <i className="fas fa-spinner fa-spin"></i>
        </span>
      );
    }
  }

  getItemContent(items) {
    return items.map((item, index) => <p key={index}>{item.name} - {item.assetid}</p>);
  }

  getBotItems() {
    const { botItems } = this.props.offer;
    return (
      <Popup
        trigger={
          <p>{`${botItems.length} item${botItems.length === 1 ? "" : "s"}`}</p>
        }
        content={this.getItemContent(botItems)}
        position="top center"
        className="chatPopup"
      />
    );
  }

  canCancelOffer() {
    return (
      this.props.offer.completed !== true && this.props.offer.botItems.length /*&& this.props.offer.failed !== true*/
    );
  }
  getLinkButton() {
    const { userItems } = this.props.offer;
    if( this.props.offer.completed || (this.props.offer.failed === true && userItems.length) )return "";
    return (
      <a
        href={`https://steamcommunity.com/tradeoffer/${this.props.offer.tradeId}`}
        target="_blank"
        rel="noopener norefferer"
      >
        <i className="fas fa-external-link-alt" />
      </a>
    );
  }

  getCancelButton() {
    const { offer } = this.props;
    const { botItems } = this.props.offer;
    return (
      <a
        onClick={() => {
          if (this.canCancelOffer()) {
            this.props.cancelOffer(this.props.offer);
          }
        }}
        className={this.canCancelOffer()?"Allowed":""}
      >
        {/*<i className={`fas fa-ban ${this.canCancelOffer() ? "" : "disabled"}`} />*/}
        {this.canCancelOffer() ? "RESEND" : "Accepted"}
      </a>
    );
  }

  getUserItems() {
    const { userItems } = this.props.offer;
    return (
      <Popup
        trigger={
          <p>{`${userItems.length} item${userItems.length === 1 ? "" : "s"}`}</p>
        }
        content={this.getItemContent(userItems)}
        offset={-15}
      />
    );
  }

  getType() {
    const { botItems } = this.props.offer;
    const { userItems } = this.props.offer;
    var sum=0; for(var i of botItems){sum+=i.price} for(var i of userItems){sum+=i.price} sum=parseInt(sum);
    return (
      <Popup
        trigger={
          <span><b>{`$${sum}`}</b> {`${botItems.length?"Won":"Join"} ${botItems.length} item${botItems.length === 1 ? "" : "s"}`}</span>
        }
        content={this.getItemContent(botItems)}
        position="top center"
        className="chatPopup"
      />
    );
  }

  render() {
    const { _id, gameId, botId, botItems, userItems, userId, type} = this.props.offer;
    var offer=this.props.offer;
    var item=botItems.length?botItems[0]:userItems[0];
    var win= ((offer.roundId&&type==1)||type==2)?"Winnings ":"Deposit ";
    return (
      <div className="OfferRow">
        <div className="GameId">{offer.roundId>1?offer.roundId:gameId}</div>
        <div className="OfferId">{offer.roundId||!gameId?"Jackpot":"Coinflip"} -
          <a target="_blank" href={offer.tradeUrl}>[Trade]</a>
          <a target="_blank" href={"https://steamcommunity.com/profiles/"+userId}> {userId} </a><br />
          - {_id} - {offer.created.substr(5,11).replace("T"," ")}<br />
          <a target="_blank" href={"https://steamcommunity.com/profiles/76561199285676992/inventory/#440_2_"+item.assetid}>[#1 assetid in inventory]</a> 
          <br /> {offer.resendhash}
        </div>
        <div className="BotId">{win} {this.getType()/*botId*/}
          <img width="18px" src={item?`https://steamcommunity-a.akamaihd.net/economy/image/${item?.icon_url}/80fx80f`:""}></img>
        </div>
        {/* <div className="UserItems">{this.getUserItems()}</div> */}
        {/* <div className="BotItems">{this.getBotItems()}</div> */}
        <div className="Status">{this.getStatus()}</div>
        <div className="Link">{this.getLinkButton()}</div>
        <div className="Cancel">{this.getCancelButton()}</div>
      </div>
    );
  }
}
