import React from 'react'
import { Route, Switch, Redirect} from 'react-router-dom'

import { AdminRake, AdminTradeOffers, AdminWithdraws, Coinflip, Jackpot, History, AdminRoute, Referrals } from './containers'
import { FAQ, NotFound, TOS, Support, ProvablyFair } from './components'

const Routes = ({ secureSocket, publicSocket }) => (
  <main>
    <Switch>
      <Route exact path="/"><Redirect to="/coinflip" /></Route>
      <Route path="/jackpot" render={props => <Jackpot secureSocket={secureSocket} publicSocket={publicSocket} {...props} />} />
      <Route path="/coinflip" render={props => <Coinflip secureSocket={secureSocket} publicSocket={publicSocket} {...props} />} />
      <Route path="/referrals" render={props => <Referrals secureSocket={secureSocket} publicSocket={publicSocket} {...props} />} />
      <Route path="/upgrader" component={Support} />
      <Route path="/faq" component={FAQ} />
      <Route path="/tos" component={TOS} />
      <Route path="/support" component={Support} />
      <Route path="/history" render={props => <History publicSocket={publicSocket} {...props} />} />
      <Route path="/provably-fair" component={ProvablyFair}/>
      <AdminRoute path="/admin/rake" component={AdminRake} />
      <AdminRoute path="/admin/trades" component={AdminTradeOffers} />
      <AdminRoute path="/admin/withdraws" component={AdminWithdraws} />
      <Route component={NotFound} />
    </Switch>
  </main>
)

export default Routes
