export default {
  app: {
    url: window.location.origin.replace(":2","")/*.replace("http://s.","https://")*/+"/",//realurl(),/*'https://domain.com/',*/
  },
  api: {
    url: window.location.origin.replace(":2","")/*.replace("http://s.","https://")*/+"/",//realurl()//'http://137.184.212.124:3001/api/'/*'https://domain.com/api/'*/
  },
  socket: {
    public: {
      param: 'socket-public',
      path: ''
    },
    secure: {
      param: 'socket-secure',
      path: '/secure'
    }
  },
  tax: {
    promo: 0.1,
    noPromo: 0.12,
    minPercentageWithoutTax: 0.85,
  },
  metadata: {
    name: 'TF2.BET - TF2 Coinflip TF2 Jackpot', //'TF2 Coinflip TF2 Jackpot TF2.BET',
    domain: 'tf2.bet',
    url: 'https://tf2.bet',
    email: 'support@tf2.bet',
    twitter: 'https://www.youtube.com/channel/UCFK3CUl-l28C4J4QCz1IktQ',
    discord: 'https://discord.gg/XPF5TbmaHg',
    steam: 'https://steamcommunity.com/groups/TF2-BET',
    useLanding: true,
    gameName: 'TF2.BET'
  },
  //Coinflip settings
  coinflip: {
    minItems: 1, //Min items per deposit
    maxItems: 500, //Max items per deposit
    minAmount: 2.00, //0.30  <---- Min deposit amount
    itemThreshold: 0.03, //Min item value
    allowedItems: ['*'],
    botIds: [
      'id64',
    ],
  },
  maintance: {
    isMaintance: false,
    maintanceDesc: '',
  }
  ,
  //Jackpot setting
  jackpot: {
    minItems: 1, //Min deposit per trade
    maxItems: 100, //Max deposit per trade
    minAmount: 2, //1.00 <-- Min deposit amount
    itemThreshold: 0.10, //Min item value
    game: {
      maxItems: 200, //Max items in jackpot
      depositsToStart: 2 //Min items to start
    },
    countdowns: {
      gameCountdown: 60 // Countdown in seconds
    },
    allowedItems: ['*'],
    numberOfPastRounds: 3,
  }
}

/*
export default {
  app: {
    url: 'http://137.184.212.124/',//'https://domain.com/',
  },
  api: {
    url: 'http://137.184.212.124:3001/api/'//'https://domain.com/api/'
  },
  socket: {
    public: {
      param: 'socket-public',
      path: ''
    },
    secure: {
      param: 'socket-secure',
      path: '/secure'
    }
  },
  tax: {
    promo: 0.05,
    noPromo: 0.10,
    minPercentageWithoutTax: 0.85,
  },
  metadata: {
    name: 'RustCave',
    domain: 'rustcave.com',
    url: 'https://rustcave.com',
    email: 'contact@rustcave.com',
    twitter: 'https://twitter.com/RustCave',
    discord: 'https://discord.gg/EKj2b8q',
    useLanding: false,
    gameName: 'Rust'
  },
  //Coinflip settings
  coinflip: {
    minItems: 1, //Min items per deposit
    maxItems: 15, //Max items per deposit
    minAmount: 0.05, //Min deposit amount
    itemThreshold: 0.05, //Min item value
    allowedItems: ['*'],
    botIds: [
      'id64',
    ],
  },
  maintance: {
    isMaintance: false,
    maintanceDesc: '',
  }
  ,
  //Jackpot setting
  jackpot: {
    minItems: 1, //Min deposit per trade
    maxItems: 15, //Max deposit per trade
    minAmount: 0.05, //Min deposit amount
    itemThreshold: 0.05, //Min item value
    game: {
      maxItems: 200, //Max items in jackpot
      depositsToStart: 2 //Min items to start
    },
    countdowns: {
      gameCountdown: 60 // Countdown in seconds
    },
    allowedItems: ['*'],
    numberOfPastRounds: 3,
  }
}



export default {
  app: {
    url: 'https://domain.com/',
  },
  api: {
    url: 'https://domain.com/api/'
  },
  socket: {
    public: {
      param: 'socket-public',
      path: ''
    },
    secure: {
      param: 'socket-secure',
      path: '/secure'
    }
  },
  tax: {
    promo: 0.05,
    noPromo: 0.10,
    minPercentageWithoutTax: 0.85,
  },
  metadata: {
    name: 'RustCave',
    domain: 'rustcave.com',
    url: 'https://rustcave.com',
    email: 'contact@rustcave.com',
    twitter: 'https://twitter.com/RustCave',
    discord: 'https://discord.gg/EKj2b8q',
    useLanding: false,
    gameName: 'Rust'
  },
  //Coinflip settings
  coinflip: {
    minItems: 1, //Min items per deposit
    maxItems: 15, //Max items per deposit
    minAmount: 0.05, //Min deposit amount
    itemThreshold: 0.05, //Min item value
    allowedItems: ['*'],
    botIds: [
      'id64',
    ],
  },
  maintance: {
    isMaintance: false,
    maintanceDesc: '',
  }
  ,
  //Jackpot setting
  jackpot: {
    minItems: 1, //Min deposit per trade
    maxItems: 15, //Max deposit per trade
    minAmount: 0.05, //Min deposit amount
    itemThreshold: 0.05, //Min item value
    game: {
      maxItems: 200, //Max items in jackpot
      depositsToStart: 2 //Min items to start
    },
    countdowns: {
      gameCountdown: 60 // Countdown in seconds
    },
    allowedItems: ['*'],
    numberOfPastRounds: 3,
  }
}

*/