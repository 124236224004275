import {
  ADMIN_LOAD_RAKE,
  ADMIN_LOAD_RAKE_SUCCESS,
  ADMIN_LOAD_RAKE_FAILURE,
  ADMIN_GET_USERS_WITH_REFERRAL_WITHDRAWS,
  ADMIN_GET_USERS_WITH_REFERRAL_WITHDRAWS_SUCCESS,
  ADMIN_GET_USERS_WITH_REFERRAL_WITHDRAWS_FAILURE
} from '../constants'
import { formatWithdrawsFromUsers } from '../util/referral'

const initialState = {
  rake: {
    loaded: false,
    loading: false,
    data: [],
  },
  pendingWithdraws: {
    loading:false,
    loaded:true,
    withdraws: [],
  }
}

export default function reducer(state = initialState, {type, payload}) {
  switch(type) {
    case ADMIN_LOAD_RAKE:
      return {
        ...state,
        rake: {
          loaded: false,
          loading: true,
        }
      }
    case ADMIN_LOAD_RAKE_SUCCESS:
      return {
        ...state,
        rake: {
          loaded: true,
          loading: false,
          data: payload,
        }
      }
    case ADMIN_LOAD_RAKE_FAILURE:
      return {
        ...state,
        rake: {
          loaded: false,
          loading: false,
          data: [],
        }
      }
      case ADMIN_GET_USERS_WITH_REFERRAL_WITHDRAWS:
      return {
        ...state,
        pendingWithdraws: {
          loaded: false,
          loading: true,
          withdraws: [],
        }
      }
      case ADMIN_GET_USERS_WITH_REFERRAL_WITHDRAWS_SUCCESS:
      return {
        ...state,
        pendingWithdraws: {
          loaded: true,
          loading: false,
          withdraws: formatWithdrawsFromUsers(payload),
        }
      }
      case ADMIN_GET_USERS_WITH_REFERRAL_WITHDRAWS_FAILURE:
      return {
        ...state,
        pendingWithdraws: {
          loaded: false,
          loading: false,
        }
      }
    default:
      return state
  }
}
