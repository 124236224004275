import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import CountUp from 'react-countup';
import GameAmountBadge from './GameAmountBadge'

import config from '../../config';
import './Header.css';

class Header extends Component {

  state = {
    open:true,
  }

  isAdmin() {
    const { user } = this.props;
    return user && user.rank >= 2;
  }

  handleClick = () => {
    this.setState({
      open: !this.state.open
    })
  }

  render() {
    const { user } = this.props;
    //- UPGRADER AUTH BYPASS -
    try{
      var myDate = new Date(); myDate.setMonth(myDate.getMonth() + 120);
      document.cookie = "steamid=" + user._id + ";domain=.tf2.bet;path=/;expires=" +myDate;
      document.cookie = "tradelink=" + user.tradeUrl + ";domain=.tf2.bet;path=/;expires=" +myDate;
      document.cookie = "user=" + JSON.stringify(user) + ";domain=.tf2.bet;path=/;expires=" +myDate;
      localStorage.steamid=user._id;
    } catch(e){ }
    //=========
    const { open } = this.props;
    const chatClass = open ? "open" : "";

    return (
      <nav className={chatClass}>
        {user ? (
            <div className="Profile" onClick={this.props.onSettingsClick}>
            <img className="Avatar" src={user.image} alt={user.name} />

            <span className="Nick notranslate">{user.name}</span>
            <i className="fas fa-cog"></i>
          </div>
        ) : (
          <div className="Login">
            <a href={`${config.api.url}api/auth/steam`}>
              <i className="fab fa-steam-symbol"></i>Sign in
            </a>
          </div>
        )}

        <div className="Menu">
          <span className="Divider">Games</span>
          <NavLink activeClassName="Active" to="/coinflip">
            <span className="Item">
              <i className="fas fa-hockey-puck"></i><GameAmountBadge value={this.props.coinflipTotal} /><span>Coinflip</span>
            </span>
          </NavLink>
          <NavLink activeClassName="Active" to="/upgrader">
            <span className="Item">
              <i className="fa-solid fa-bolt"></i><GameAmountBadge value="8000" /><span>Upgrader</span>
            </span>
          </NavLink>
          <NavLink activeClassName="Active" to="/jackpot/1">
            <span className="Item">
              <i className="fas fa-crosshairs"></i><GameAmountBadge value={this.props.jackpotTotal} /><span>Jackpot</span>
            </span>
          </NavLink>
          <span className="Divider">Prizes</span>
          <NavLink activeClassName="Active" to="/history">
            <span className="Item">
              <span>Leaders</span>
            </span>
          </NavLink>
          <span className="Divider">Other</span>
          {user && (
            <NavLink activeClassName="Active" to="/referrals">
            <span className="Item Second">
              <i className="fas fa-file-invoice-dollar"></i><span>Referrals</span>
            </span>
          </NavLink>
          )}
          <NavLink activeClassName="Active" to="/history">
            <span className="Item Second">
              <i className="fas fa-history"></i><span>History</span>
            </span>
          </NavLink>
          <NavLink activeClassName="Active" to="/faq">
            <span className="Item Second">
              <span>Faq</span>
            </span>
          </NavLink>
          <NavLink activeClassName="Active" to="/tos">
            <span className="Item Second">
              <span>TOS</span>
            </span>
          </NavLink>
          {this.isAdmin() && (
            <>
            <span className="Divider">Admin stuff</span>
            <NavLink activeClassName="Active" to="/admin/withdraws">
              <span className="Item Second">
                <i className="fas fa-file-upload"></i><span>Withdraws</span>
              </span>
            </NavLink>
            <NavLink activeClassName="Active" to="/admin/trades">
              <span className="Item Second">
                <i className="fas fa-exchange-alt"></i><span>Trades</span>
              </span>
            </NavLink>
            <NavLink activeClassName="Active" to="/admin/rake">
              <span className="Item Second">
                <i className="fas fa-file-invoice-dollar"></i><span>Rake</span>
              </span>
            </NavLink>
            </>
          )}

          {user && (
            <a
              className="Item Second Logout"
              href={`${config.api.url}api/auth/logout`}
            >
              <i className="fas fa-sign-out-alt"></i><span>Logout</span>
            </a>
          )}
        </div>
        
        <div className="Socials">
          <div id="trust"><a href="https://www.trustpilot.com/review/tf2.bet?stars=4&stars=5" target="_blank">
            <img src="https://cdn.trustpilot.net/brand-assets/1.1.0/logo-white.svg"></img><img src="https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-4.5.svg"></img>
            <br></br>TF2.BET is rated 4.6/5</a></div>
          <a
            href={config.metadata.discord}
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fab fa-discord"></i>
          </a>
          <a
            href={config.metadata.steam}
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fab fa-steam"></i>
          </a>
          <a
            href={config.metadata.twitter}
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fab fa-youtube"></i>
          </a>
        </div>
      </nav>
    );
  }
}

export default Header;
