import React, { Component } from 'react'
import { Popup } from 'semantic-ui-react'

export default class CoinflipInventoryItem extends Component {

  constructor(props) {
    super(props)

    this.onItemClick = this.onItemClick.bind(this)
  }

  /*
   *  image: image of the item
   *  price: price of the item
   *  name: name of the item
   *  tier(?): tier for class highlighting
   */

  onItemClick() {
    if (!this.props.disabled && !this.props.selected) {
      this.props.select()
    } else if (!this.props.disabled && this.props.selected) {
      this.props.unselect()
    }
  }

  getComponent() {
    return (
      <div className={'DepositItem ' + (this.props.disabled ? 'disabled' : '') + (this.props.selected ? 'selected' : '')} onClick={this.onItemClick}>
        <img src={this.props.image} alt="item" />
        <span>${this.props.price}</span>
        {this.props.selected &&
          <i className="fas fa-check" />
        }
        {this.props.disabled &&
          <i className="fas fa-ban" />
        }
      </div>
    )
  }

  render() {
    return (
      <Popup
        trigger={this.getComponent()}
        content={this.props.name+ (this.props.image.split('#')[3] ? ' ' + decodeURI(this.props.image.split('#')[3].split('#')[0]) : '')}
        className="chatPopup"
        position="top center"
        mouseEnterDelay={500}
        mouseLeaveDelay={100}
      />
    )
  }

}
