import React, { Component } from 'react';
import { TimelineMax, Power4 } from 'gsap';
import CSSPlugin from 'gsap/CSSPlugin'
import { getDepositChance } from '../../util/jackpot';
import JackpotRollDeposit from './JackpotRollDeposit';

const C = CSSPlugin;

export default class JackpotRoller extends Component {
  componentDidMount() {
    if (this.props.round) {
      const tl = new TimelineMax({
        onComplete: this.props.onComplete,
      });

      let rollerWidthForRolls = 550;

      if(Math.floor(this.props.round.winningPercentage) % 2 === 0) {
        rollerWidthForRolls = 650;
      }

      const winnerPercent = this.props.round.winningPercentage + rollerWidthForRolls;

      tl.to(this.refs.roller, 10, {
        x: `-${winnerPercent}%`,
        ease: Power4.easeOut,
        delay: 0.5,
      });
    }
  }

  getColors() {
    const { round } = this.props;
    const deposits = round.deposits;
    const colors = [
      "13E773",
      "137AE7",
      "7013E7",
      "E7D413",
      "E71359",
      "E75F13",
      "E713E4",
      "139BE7",
      "AFEA09",
      "A44CFC",
      "E47676",
      "30E5AF",
      "4F69AC",
    ];

    let sortedColors = []; //Sorted colors for players

    for (let key in deposits) {
      function checkId(userId) {
        return userId.id === deposits[key].id;
      }
      let alreadyIndex = deposits.findIndex(checkId);
      if (alreadyIndex === -1) {
        sortedColors.push(colors[key]);
      } else {
        sortedColors.push(colors[alreadyIndex]);
      }
    }

    return sortedColors;
  }

  getPlayers() {
    const { round } = this.props;
    const deposits = round.deposits;
    const sortedColors = this.getColors();

    return deposits.map((deposit, key) => (
      <JackpotRollDeposit
        key={key}
        deposit={deposit}
        color={sortedColors[key]}
        chances={getDepositChance(round, deposit)}
      ></JackpotRollDeposit>
    ));
  }

  render() {


    const UsersBar = (
      <div className="RollItem">
        <div className="PlayerItems">{this.getPlayers()}</div>
      </div>
    )


    return (
      <div className="RollOverflow Rolling">
        <span className="Indicator"></span>
        <div className="RollBox">
          <div className="RollItems" ref="roller">
            {UsersBar}
            {UsersBar}
            {UsersBar}
            {UsersBar}
            {UsersBar}
            {UsersBar}
            {UsersBar}
            {UsersBar}
            {UsersBar}
            {UsersBar}
          </div>
        </div>
      </div>
    );
  }
}
