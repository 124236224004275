import React, { Component } from 'react'
import Modal from 'react-modal'

import './TradeOfferModal.css'

export default class TradeOfferModal extends Component {

  constructor(props) {
    super(props)

    this.requestClose = this.requestClose.bind(this)
  }

  /*  PROPS
   *
   *  tradeOffer - object of the trade offer
   *
   */

  requestClose() {
    setTimeout(this.props.onClose, 1000) /* wait 1 second before closing to allow href */
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onClose}
        closeTimeoutMS={200}
        contentLabel="Modal"
        className={'Modal ModalTradeOffer'}
        overlayClassName={'Modal__Overlay'}
      >
        <div className="ModalHeader">
          <span>Accept your tradeoffer</span>
        </div>
        <div className="ModalContent">
          <div className="TradeOfferContent">
            <div className="OfferWarnings">
              <div className="OfferWarning">
                <i className="fas fa-ban"></i>
                <p>Be cautious. Scammers and impersonators may try to trick you.</p>
              </div>
              <div className="OfferWarning">
                <i className="fas fa-clock"></i>
                <p>Keep an eye out for your trade offer and confirmation.</p>
              </div>
            </div>
            <div>
              <p>Security Code:</p><span>{this.props.tradeOffer ? this.props.tradeOffer.offerId : null}</span>
            </div>
          </div>
        </div>
        <div className="ModalFooter ModalOffersFooter">
            <a onClick={this.requestClose} href={`https://steamcommunity.com/tradeoffer/${this.props.tradeOffer ? this.props.tradeOffer.id : null}`} target="_blank" rel="noopener norefferer">
              <button><i className="fa-brands fa-chrome"></i> Open in browser</button>
            </a>
            <a onClick={this.requestClose} href={`steam://url/ShowTradeOffer/${this.props.tradeOffer ? this.props.tradeOffer.id : null}`} href={`steam://url/ShowTradeOffer/${this.props.tradeOffer ? this.props.tradeOffer.id : null}`}>
              <button><i className="fa-brands fa-steam"></i> Open in Steam</button>
            </a>
        </div>
      </Modal>
    )
  }

}
