import React from 'react';
import { NavLink } from 'react-router-dom';

import './404.css';

export default function NotFound() {
  return (
    <div id="not-found">
      <h1>404 - Page not found.</h1>
      <div id="go-back">
        <NavLink to="/">Go back</NavLink>
      </div>
    </div>
  )
}
