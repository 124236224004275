import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'

import reducers from '../reducers'
import socketMiddleware from '../middleware/socketMiddleware'

const createFinalStore = (history, sockets, initialState) => {
  const middlewares = [
    routerMiddleware(history),
    thunk,
    socketMiddleware(sockets[0]),
    socketMiddleware(sockets[1]),
  ]

  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(...[logger])
  }

  const store = createStore(
    reducers(history),
    initialState,
    compose(
      applyMiddleware(
        routerMiddleware(history),
        ...middlewares,
      )
    ),
  )

  return store
}

export default function configureStore(initialState, history, sockets) {
  return createFinalStore(history, sockets, initialState)
};
