import React, { Component } from "react";
import CoinflipGameItem from "./CoinflipGameItem";
import { didCreatorWin } from "../../util/coinflip";
import moment from 'moment'
import { Popup } from "semantic-ui-react";

const black = "Blue";
const red = "Red";

const IMAGE_URL = "https://steamcommunity-a.akamaihd.net/economy/image/";

export default class CoinflipGame extends Component {
  renderItems() {
    const sorted = this.sortedItems();
    if (sorted.length > 6) {
      const items = sorted
        .slice(0, 6)
        .map((item, key) => (
          <CoinflipGameItem
            key={key}
            name={item.name}
            image={`${IMAGE_URL}${item.icon_url}`}
            price={item.price}
          />
        ));
      return (
        <div className="CoinflipItems">
          {items}
          <span>+{sorted.length - 6}</span>
        </div>
        
      );
    }
    
    const items = sorted
        .map((item, key) => (
          <CoinflipGameItem
            key={key}
            name={item.name}
            image={`${IMAGE_URL}${item.icon_url}`}
            price={item.price}
          />
        ));
    return(
      <div className="CoinflipItems">
          {items}
        </div>
    );
  }

  sortedItems() {
    return [
      ...this.props.game.creator.items,
      ...this.props.game.joiner.items,
    ].sort((a, b) => {
      return b.price - a.price;
    });
  }

  getTotalValue() {
    let total = 0.0;
    for (const index in this.props.game.creator.items) {
      const item = this.props.game.creator.items[index];
      if (item && item.price) {
        total += parseFloat(item.price);
      }
    }
    if (this.props.game.joiner && this.props.game.joiner.items) {
      for (const index in this.props.game.joiner.items) {
        const item = this.props.game.joiner.items[index];
        if (item && item.price) {
          total += parseFloat(item.price);
        }
      }
    }
    return parseFloat(Number(total).toFixed(2));
  }

  getStartingSide() {
    if (this.props.game.startingSide === "red") {
      return red;
    }
    return black;
  }

  getSecondPlayerSide() {
    if (this.props.game.startingSide === "red") {
      return black;
    }
    return red;
  }

  getImages() {
    const { game } = this.props;
    if (didCreatorWin(game)) {
      return [
        game.creator.image,
        game.joiner.image,
        game.startingSide === "black" ? black : red,
      ];
    } else {
      return [
        game.joiner.image,
        game.creator.image,
        game.startingSide === "black" ? red : black,
      ];
    }
  }

  getStatus() {
    const [winner, loser, side] = this.getImages();
    if(side === black) {
      return (
        <div className="CoinflipStatus">
        <span className={side}></span><img src={winner} className="Winner" alt="winner" />
      </div>
      )
    }
    return (
      <div className="CoinflipStatus">
        <span className={side}></span><img src={winner} className="Winner" alt="winner" />
      </div>
    );
  }

  getPlayers() {
    const { creator, joiner } = this.props.game;

    return (
      <div className="CoinflipPlayers">
        <a
          href={`https://steamcommunity.com/profiles/${creator.id}`}
          target="_blank"
          className={`${this.getStartingSide()}`}
          rel="noopener noreferrer"
        >
          <div className={`Player ${this.getStartingSide()}`}>
            <img src={creator.image} alt="Creator" />
            
          </div>
        </a>
        <span>vs</span>
        {joiner.image ? (
          <a
            href={`https://steamcommunity.com/profiles/${joiner.id}`}
            target="_blank"
            className={`${this.getSecondPlayerSide()}`}
            rel="noopener noreferrer"
          >
            <div className={`Player ${this.getSecondPlayerSide()}`}>
              <img src={joiner.image} alt="Joiner" />
            </div>
          </a>
        ) : (
          <div className="Anonymous"></div>
        )}
      </div>
    );
  }

  render() {
    const totalValue = this.getTotalValue();
    return (
      <div className="CoinflipGame recent" id={`game${this.props.game._id}`}>
        {this.getPlayers()}           
        {this.renderItems()}
        <small><Popup
          trigger={
            <span className="Fail">
              {`${moment(new Date(this.props.game.dateCompleted)).fromNow()}`}
            </span>
          }
          content={this.props.game.resend}
          position="top center"
          className="chatPopup"
        />
        </small>
        <small></small>
        <div className="CoinflipValue notranslate">
          <span className="Value">{`$${totalValue}`}</span>
        </div>
        {this.getStatus()}
        <div className="CoinflipOptions">
          <button onClick={this.props.onVerify}>Verify</button>
          <button onClick={this.props.onView}>View</button>
        </div>
      </div>
    );
  }
}
