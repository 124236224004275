import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CountUp from 'react-countup';
import { NotificationManager } from'react-notifications';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Helmet } from 'react-helmet-async'

import ReferralsWithdrawTable from './ReferralsWithdrawTable';
import config from '../../config';
import './Referrals.css';

import {
  loadReferralsData,
  createReferralCode,
  withdrawReferralEarnings,
} from '../../actions';

class Referrals extends Component {
  componentWillMount() {
    this.props.loadReferralsData();
  }

  calculateLevelProgress() {
    const { referrals } = this.props;
    if (referrals.level === referrals.levels.length) {
      return 100;
    }
    return (((referrals.depositorsCount - referrals.depositorsNeeded) / (referrals.levels[referrals.level][1] - referrals.levels[referrals.level - 1][1])) * 100);
  }

  higherLevelNeeded() {
    const { referrals } = this.props;
    if (referrals.level === referrals.levels.length) {
      return referrals.depositorsNeeded;
    }
    return referrals.levels[referrals.level][1];
  }

  renderLevel(item, index) {
    return (
      <div className="LevelItem">
        <div className="LevelIcon">
          <i className={`fas fa-medal Level-${index + 1}`}></i>
        </div>
        <div className="LevelGrade">
          Level {index + 1}
        </div>
        <div className="LevelValue">
          {(item[0] * 100).toFixed(3)}%
        </div>
        <div className="DepositorsNeededForLevel">
          <i className="fas fa-user-astronaut"></i>{" "}{item[1]}
        </div>
      </div>
    )
  }

  renderAllLevels() {
    const { referrals } = this.props;
    const { levels } = referrals

    return (
      <div className="LevelsBox">
        <h3>All levels</h3>
        <div className="LevelItem Head">
        <div></div>
        <div className="LevelGrade">
          Level
        </div>
        <div className="LevelValue">
          Fee
        </div>
        <div className="DepositorsNeededForLevel">
          Depositors
        </div>
      </div>
        {levels.map((item, index) => this.renderLevel(item, index))}
      </div>
      )

  }

  render() {
    const { referrals, user } = this.props;
    if (!user) {
      return (
        <div className="container">
          <h1>You need to login first</h1>
        </div>
      )
    }
    
    const refLink = `${config.metadata.url}?r=${user._id}`.replace("tf2.bet","TF2.BET");
    
    return (
      <div className="container">
        <Helmet>
          <title>Referrals - {config.metadata.name}</title>
          <meta name="description" content="Referrals - {config.metadata.name}"/>
        </Helmet>
        <h2>Affiliates</h2>

        <div className="ReferralsProgress">
          <div>
            <i className="fas fa-user-astronaut"></i>{" "}
            {referrals.depositorsNeeded}
          </div>
          <div className="LevelProgress">
            <div
              className={`LevelProgressInner Level-${referrals.level}`}
              style={{ width: `${this.calculateLevelProgress(referrals)}%` }}
            ></div>
          </div>
          <div>
            <i className="fas fa-level-up-alt"></i>
            <i className="fas fa-user-astronaut"></i>{" "}{this.higherLevelNeeded()}
          </div>
        </div>
        
        <div className="ReferralUserInfo">
          <div className="ReferralUserInfoBlock">
            <div>Your referral level</div>
            <div className="UserReffLevel"><i className={`fas fa-medal Level-${referrals.level}`}></i> {user.referralCut ? 'custom' : referrals.level}</div>
          </div>
          <div className="ReferralUserInfoBlock">
            <div>Your rake from referral winnings</div>
            <div className="Value" ><i className="fas fa-percentage"></i> { ((user.referralCut ? user.referralCut : referrals.feeFromRake) * 100).toFixed(3)}</div>
          </div>
        </div>
        
        <div className="ReferralCodeShow">
          <span>Your referral link:</span>
          <input className="ReferralCodeInput" readOnly value={refLink} />
          <CopyToClipboard
            text={refLink}
            onCopy={() =>
              NotificationManager.success("Referral link saved to clipboard!")
            }
          >
            <button className="CopyButton">
              <i className="fas fa-copy"></i>
            </button>
          </CopyToClipboard>
        </div>

        <div className="ReferralInfo">
          <div className="ReferralStats">
            <div className="ReferralStat">
              <span>Total Earnings</span>
              <CountUp
                className="Value"
                start={0}
                end={referrals.referralTotalEarnings}
                duration={2}
                prefix="$"
                decimals={2}
              />
            </div>
            <div className="ReferralStat">
              <span>Referrals</span>
              <CountUp
                className="Value"
                start={0}
                end={referrals.referralsCount}
                duration={2}
              />
            </div>
            <div className="ReferralStat">
              <span>Depositors</span>
              <CountUp
                className="Value"
                start={0}
                end={referrals.depositorsCount}
                duration={2}
              />
            </div>
          </div>

          <div className="ReferralAvailable">
            <CountUp
              className="Value"
              start={0}
              end={referrals.referralAvailableEarnings}
              duration={2}
              prefix="$"
              decimals={2}
            />
            <button onClick={() => this.props.withdrawReferralEarnings()}>
              Withdraw
            </button>
          </div>
        </div>
        <ReferralsWithdrawTable withdrawHistory={referrals.referralWithdraws} />
        { this.renderAllLevels() }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    referrals: state.user.referrals,
    user: state.auth.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadReferralsData,
      createReferralCode,
      withdrawReferralEarnings,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Referrals);
