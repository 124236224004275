import React, { Component } from "react";
import Modal from "react-modal";
import CoinflipOfferRow from "./CoinflipOfferRow";

import "./CoinflipOffersModal.css";

export default class CoinflipOffersModal extends Component {
  constructor(props) {
    super(props);

    this.renderOfferTable = this.renderOfferTable.bind(this);
    this.renderOffers = this.renderOffers.bind(this);
  }

  componentWillMount() {
    this.props.requestOffers();
  }

  renderOfferTable() {
    if (this.props.offers.loading) {
      return (
        <div className="InventoryLoader">
          <div className="sk-chase">
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="CoinflipTradeOffersContent">
          <div className="OfferRow Header">
            <span>Game</span>
            <span>Offer Id</span>
            <span>Bot Id</span>
            <span>Status</span>
            <span>Link</span>
            <span></span>
          </div>
          {this.renderOffers()}
        </div>
      );
    }
  }

  renderOffers() {
    return this.props.offers.offers.map((offer, index) => (
      <CoinflipOfferRow
        isResending={this.props.isResending}
        offer={offer}
        key={index}
        cancelOffer={this.props.cancelOffer}
        resendOffer={this.props.resendOffer}
      />
    ));
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onClose}
        closeTimeoutMS={200}
        contentLabel="Modal"
        className={"Modal CoinflipTradeoffersModal"}
        overlayClassName={"Modal__Overlay"}
      >
        <div className="ModalHeader">
          <span>Your coinflip offers</span>
        </div>
        <div className="ModalContent">{this.renderOfferTable()}</div>
        <div className="ModalFooter ModalOffersFooter">
          <div className="FooterDescrition">
            <p>Users have 48 hours to accept any winnings sent to them.</p>
            <p>
              Each trade offer will last for 2 minutes but can be resent using
              support.
            </p>
          </div>
          <div className="FooterButtons">
            <button onClick={this.props.requestOffers}>Refresh</button>
            <button onClick={this.props.onClose}>Close</button>
          </div>
        </div>
      </Modal>
    );
  }
}
