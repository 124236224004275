import React, { Component } from 'react'
import { Input, Dropdown, Button } from 'semantic-ui-react'
import AdminRakeTable from './AdminRakeTable'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Helmet } from 'react-helmet-async'

import config from '../../../config'
import {
  loadRakeHistory,
  withdrawRake,
  withdrawAllRake
} from '../../../actions'

import '../Admin.css'

const sortOptions = [
  {
    key: 'available',
    text: 'Available',
    value: 'available'
  },
  {
    key: 'withdrawn',
    text: 'Withdrawn',
    value: 'withdrawn'
  },
  {
    key: 'date descending',
    text: 'Date Descending',
    value: 'date descending'
  },
  {
    key: 'date ascending',
    text: 'Date Ascending',
    value: 'date ascending'
  }
]

class AdminRake extends Component {

  constructor(props) {
    super(props)

    this.updateSearch = this.updateSearch.bind(this)

    this.state = {
      filter: 'date ascending',
      search: null
    }
  }

  onDropdownChange(data) {
    this.setState({ filter: data.value })
  }

  updateSearch(event) {
    if (event.key === 'Enter') {
      if(event.target.value === "") {
        this.setState({search: null})
      } else {
        this.setState({ search: event.target.value })
      }
    }
  }

  render() {
    const { loadRakeHistory, rake, withdrawRake } = this.props
    const { search, filter } = this.state
    return (
      <div className="container">
        <Helmet>
          <title>Admin - {config.metadata.name}</title>
          <meta name="description" content="Admin - {config.metadata.name}"/>
        </Helmet>
        <div className="AdminHeader">
          <Input
            placeholder={'Search for an item...'}
            className="AdminInput"
            onKeyDown={this.updateSearch}
          />
          <Button className="WithdrawAll" text='Withdraw All' onClick={this.props.withdrawAllRake}>Withdraw All</Button>
          <Dropdown className="AdminDropdown" options={sortOptions} onChange={(event, data) => this.onDropdownChange(data)} />
        </div>
        <AdminRakeTable withdrawRake={withdrawRake} loadRake={loadRakeHistory} rake={rake} search={search} filter={filter} />
      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    rake: state.admin.rake
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    loadRakeHistory,
    withdrawRake,
    withdrawAllRake
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminRake)
