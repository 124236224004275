import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { NavLink, withRouter } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { Helmet } from 'react-helmet-async';

import {
  saveTradeURL as tradeURLAction,
  loadAuth,
  reloadAuth,
  updateConnectedUsers,
  loadJackpot,
  newJackpotRound,
  updateJackpotRound,
  loadCoinflipGames,
  removeCoinflipGame,
  updateCoinflipGame,
  addCoinflipGame,
} from '../../actions'
import Header from '../../components/Header/Header'
import UserProfileModal from '../../components/Modals/UserProfileModal/UserProfileModal'
import Chat from '../Chat/Chat'
import Landing from '../Landing/Landing'
import Routes from '../../routes'
import { isTradeURL } from '../../util/url'
import config from '../../config'
import { getJackpotTotal } from '../../util/jackpot'
import { getCoinflipsTotal } from '../../util/coinflip'
import logo from "../../static/logo.png";

import './App.css'
import 'react-notifications/lib/notifications.css';

class App extends Component {

  constructor(props) {
    super(props)

    this.saveTradeURL = this.saveTradeURL.bind(this)

    this.state = {
      userProfileModal: false,
      chatOpen: false,
      menuOpen: false,
    }
    
  }

  handleChat = () => {
    if(this.state.menuOpen) {
      this.setState({
        menuOpen: !this.state.menuOpen
      })
    }
    this.setState({
      chatOpen: !this.state.chatOpen
    })
  }

  handleMenu = () => {
    if(this.state.chatOpen) {
      this.setState({
        chatOpen: !this.state.chatOpen
      })
    }
    this.setState({
      menuOpen: !this.state.menuOpen
    })
  }
  
  componentWillMount() {
    const refid = new URLSearchParams(window.location.search).get('r')

    if (refid) {
      document.cookie = `refid=${refid}`;
    }

    if (!this.props.auth.loaded) {
      this.props.loadAuth()
    }

    this.props.loadCoinflipGames()
    this.props.loadJackpot()

    this.props.publicSocket.on('NOTIFY', this.notify)
    this.props.secureSocket.on('NOTIFY', this.notify)
    this.props.publicSocket.on('USERS_CONNECTED', this.props.updateConnectedUsers)

    this.props.publicSocket.on('JACKPOT_NEW_ROUND', this.props.newJackpotRound)
    this.props.publicSocket.on('JACKPOT_UPDATE_ROUND', this.props.updateJackpotRound)

    this.props.publicSocket.on('COINFLIP_NEW_GAME', this.props.addCoinflipGame)
    this.props.publicSocket.on('COINFLIP_UPDATE_GAME', this.props.updateCoinflipGame)
    this.props.publicSocket.on('COINFLIP_REMOVE_GAME', this.props.removeCoinflipGame)
  }

  componentWillUnmount() {
    this.props.publicSocket.off('NOTIFY')
    this.props.secureSocket.off('NOTIFY')
    this.props.publicSocket.off('USERS_CONNECTED')

    this.props.publicSocket.off('JACKPOT_NEW_ROUND')
    this.props.publicSocket.off('JACKPOT_UPDATE_ROUND')

    this.props.publicSocket.off('COINFLIP_NEW_GAME')
    this.props.publicSocket.off('COINFLIP_UPDATE_GAME')
    this.props.publicSocket.off('COINFLIP_REMOVE_GAME')
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.auth.user && nextProps.auth.user) {
      setTimeout(() => {
        this.setState({
          userProfileModal: !nextProps.auth.user.tradeUrl
        })
      }, 1000)
    }

    if (nextProps.userState.forceRefresh && !this.props.auth.reloading) {
      this.props.reloadAuth()
      NotificationManager.success('Trade URL saved successfully')
      this.setState({
        userProfileModal: false
      })
    }

  }

  saveTradeURL({ inputRef: { value } }) {
    if (!value || !isTradeURL(value)) {
      NotificationManager.error('Enter a valid trade URL')
      return;
    }
    this.props.tradeURLAction(value)
  }

  notify({ type, message, header }) {
    switch(type) {
      case 'error':
        NotificationManager.error(message, header)
        break
      case 'info':
        NotificationManager.info(message, header)
        break
      case 'warning':
        NotificationManager.warning(message, header)
        break
      case 'success':
        NotificationManager.success(message, header)
        break
    }
  }

  render() {
    const { user, loading } = this.props.auth

    if (user && user.ban && user.ban.isBanned) {
      return <p>You are banned for {user.ban.reason}.</p>
    }

    return (
      <div className="App">
        <Helmet>
          <meta name="description" content="TF2 Coinflip, TF2.BET is one of the fastest TF2 Jackpot & Trading bot."/>
          <meta name="viewport" content="width=device-width, initial-scale=1"/>
          <link rel="shortcut icon" href="./favicon.ico"/>
          <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap" rel="stylesheet"/>
          <title>TF2.BET - TF2 Coinflip TF2 Jackpot</title>
        </Helmet>
        { loading ? (
            <div className="AppLoading">
              <div className="sk-chase">
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
              </div>
            </div>
          ) : (
            <div className="Content">
              {!user && config.metadata.useLanding && 
                <Landing />
              }
              <div className={`OutsideToggle ${this.state.menuOpen ? "show" : ""}`} onClick={this.handleMenu}></div>
              <div className={`OutsideToggle ${this.state.chatOpen ? "show" : ""}`} onClick={this.handleChat}></div>
              <div className="HeadBar">
                <div className="Toggler" onClick={this.handleMenu}><i className="fas fa-bars"></i></div>
                  <div className="Logo">
                    <NavLink to="/">
                      <img src={logo} alt="RustShuffle Logo"/>
                    </NavLink>
                  </div>
                  <div className="Toggler" onClick={this.handleChat}><i className="fas fa-comments"></i></div>
              </div>
              <Header
                user={user}
                onSettingsClick={() => this.setState({ userProfileModal: true })}
                open={this.state.menuOpen}
                coinflipTotal={getCoinflipsTotal(this.props.coinflips)}
                jackpotTotal={getJackpotTotal(this.props.jackpotCurrentRound)}
              />
              <Routes secureSocket={this.props.secureSocket} publicSocket={this.props.publicSocket} />
              <Chat secureSocket={this.props.secureSocket}  open={this.state.chatOpen} />
              {user && <UserProfileModal user={user} userState={this.props.userState} onClick={this.saveTradeURL} isOpen={this.state.userProfileModal} close={() => this.setState({userProfileModal: false})} />}
            </div>
          ) }
          <NotificationContainer />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    userState: state.user,
    notify: state.notify,
    coinflips: state.coinflip.games,
    jackpotCurrentRound: state.jackpot.currentRound,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loadAuth,
    tradeURLAction,
    reloadAuth,
    updateConnectedUsers,
    loadJackpot,
    newJackpotRound,
    updateJackpotRound,
    loadCoinflipGames,
    removeCoinflipGame,
    updateCoinflipGame,
    addCoinflipGame,
  }, dispatch)
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(App))
