import React, { Component } from 'react';
import { Menu, Popup } from 'semantic-ui-react';
import { NotificationManager } from 'react-notifications';

const ITEMS_PER_PAGE = 2000;

class AdminWithdrawsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
    };

    this.data = props.pendingWithdrawsWithUser
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.pendingWithdrawsWithUser !== this.props.pendingWithdrawsWithUser) {
      this.data = nextProps.pendingWithdrawsWithUser
    }

    if (nextProps.filter !== this.props.filter) {
      this.sortData(nextProps.filter)
    } else if (nextProps.search && nextProps.search !== this.props.search) {
      this.sortBySearch(nextProps.search)
    }
  }

  sortData(filter) {
    if (filter === 'date ascending') {
      this.data.sort((a, b) => {
        return new Date(b.dateCreated) - new Date(a.dateCreated)
      })
    } else if (filter === 'date descending') {
      this.data.sort((a, b) => {
        return new Date(a.dateCreated) - new Date(b.dateCreated)
      })
    } else if (filter === 'not completed') {
      this.data.sort((a, b) => {
        return a.completed - b.completed
      })
    } else if (filter === 'completed') {
      this.data.sort((a, b) => {
        return b.completed - a.completed
      })
    }
    this.setState({ currentPage: 1 })
  }

  get maxPages() {
    const { data } = this
    if (!data || data.length === 0) {
      return 0
    }

    return Math.ceil(data.length / ITEMS_PER_PAGE)
  }

  setCurrentPage(pageNumber) {
    if (pageNumber === 0) {
      pageNumber = 1
    } else if (pageNumber > this.maxPages) {
      pageNumber = this.maxPages
    }
    this.setState({ currentPage: pageNumber })
  }

  renderPaginationItems() {
    const maxPages = this.maxPages;
    if (maxPages === 0) {
      return (
        <Menu floated="right" pagination className="HistoryPagination">
          <Menu.Item as="a">
            <i className="fas fa-chevron-left"></i>
          </Menu.Item>
          <Menu.Item as="a">0</Menu.Item>
          <Menu.Item as="a">
            <i className="fas fa-chevron-right"></i>
          </Menu.Item>
        </Menu>
      );
    }
    var { currentPage } = this.state;
    if (currentPage > maxPages) {
      currentPage = maxPages;
    }

    return (
      <Menu floated="right" pagination className="HistoryPagination">
        <Menu.Item as="a" onClick={() => this.setCurrentPage(1)}>
          <i className="fas fa-angle-double-left"></i>
        </Menu.Item>
        <Menu.Item as="a" onClick={() => this.setCurrentPage(currentPage - 1)}>
          <i className="fas fa-chevron-left"></i>
        </Menu.Item>
        {currentPage - 2 > 0 && (
          <Menu.Item
            as="a"
            onClick={() => this.setCurrentPage(currentPage - 2)}
          >
            {currentPage - 2}
          </Menu.Item>
        )}
        {currentPage - 1 > 0 && (
          <Menu.Item
            as="a"
            onClick={() => this.setCurrentPage(currentPage - 1)}
          >
            {currentPage - 1}
          </Menu.Item>
        )}
        <Menu.Item as="a" active>
          {currentPage}
        </Menu.Item>
        {currentPage + 1 <= maxPages && (
          <Menu.Item
            as="a"
            onClick={() => this.setCurrentPage(currentPage + 1)}
          >
            {currentPage + 1}
          </Menu.Item>
        )}
        {currentPage + 2 <= maxPages && (
          <Menu.Item
            as="a"
            onClick={() => this.setCurrentPage(currentPage + 2)}
          >
            {currentPage + 2}
          </Menu.Item>
        )}
        <Menu.Item as="a" onClick={() => this.setCurrentPage(currentPage + 1)}>
          <i className="fas fa-chevron-right"></i>
        </Menu.Item>
        <Menu.Item as="a" onClick={() => this.setCurrentPage(maxPages)}>
          <i className="fas fa-angle-double-right"></i>
        </Menu.Item>
      </Menu>
    );
  }

  confirmSetComplete(completedId, name) {
    const isConfirmed = window.confirm(
      `Confirm you sent referrals earnings to ${name}!`
    );
    if (isConfirmed) {
      return this.props.setWithdrawCompleted(completedId);
    }
    return NotificationManager.error(
      `Canceled "Set Confirmed" request for ${name}`
    );
  }

  renderWithdrawItem(item, index) {
    const dateCreated = new Date(item.dateCreated);
    let dateCompleted;
    if (item.completed) {
      dateCompleted = new Date(item.dateCompleted);
    }

    return (
      <div className={`AdminRakeRow ${item.completed ? 'withdrawed':''}`} key={index}>
        <div className="AdminWithdrawId">{item._id}</div>
        <div className="AdminWithdrawDateCreated">
          <i className="fas fa-calendar-alt"></i> {dateCreated.toLocaleDateString()}
        </div>
        <div className="AdminWithdrawDateValue">${item.value.toFixed(2)}</div>
        <div className="AdminWithdrawDateTradeUrl">
          <Popup 
            content={`${item.user.name} tradelink`}
            trigger={<a target="_blank" href={item.user.tradeUrl} rel="noreferrer noopener"><i className="fas fa-link"></i></a>}
            className="chatPopup"
          />
        </div>
        <div className="AdminWithdrawDateStatus">
          {!item.completed ? (
            <button
              onClick={() => this.confirmSetComplete(item._id, item.user.name)}
            >
              Set Completed
            </button>
          ) : (
            <Popup
              content={`Completed at ${dateCompleted.toLocaleDateString()}`}
              trigger={<i className="fas fa-check"></i>}
              className="chatPopup"
            />
          )}
        </div>
      </div>
    );
  }

  renderTable() {
    const { data } = this
    const { currentPage } = this.state
    if (!data) {
      return null
    }

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
    const selection = data.slice(startIndex, startIndex + ITEMS_PER_PAGE)

    return selection.map((item, index) => this.renderWithdrawItem(item, index))
  }

  render() {
    return (
      <>
        {this.renderPaginationItems()}

        <div className={`AdminRakeRow`}>
          <div className="AdminWithdrawId">Id</div>
          <div className="AdminWithdrawDateCreated">Date Created</div>
          <div className="AdminWithdrawDateValue">Value</div>
          <div className="AdminWithdrawDateTradeUrl">Tradelink</div>
          <div className="AdminWithdrawDateStatus">Status</div>
        </div>

        {this.renderTable()}

        {this.renderPaginationItems()}
      </>
    );
  }
}

export default AdminWithdrawsTable;
