import React, { Component } from 'react'
import { Table, Menu, Icon, Popup } from 'semantic-ui-react'
import moment from 'moment'
var lastid=0;
const failureReasons = {
  "1": "Invalid",
	"2": "Active",
	"3": "Accepted",
	"4": "Countered",
	"5": "Expired",
	"6": "Canceled",
	"7": "Declined",
	"8": "InvalidItems",
	"9": "CreatedNeedsConfirmation",
	"10": "CanceledBySecondFactor",
	"11": "InEscrow"
}

const ITEMS_PER_PAGE = 505
const IMAGE_URL = 'https://steamcommunity-a.akamaihd.net/economy/image/'
var ogdata=0;
class AdminTradesTable extends Component {

  constructor(props) {
    super(props)

    this.state = {
      currentPage: 1
    }
    this.data = props.offers.data
  }

  componentWillMount() {
    const { offers, loadOffers, filter } = this.props
    if (!offers.loaded && !offers.loading) {
      loadOffers()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.offers.data !== this.props.offers.data) {
      this.data = nextProps.offers.data
      if (this.data && nextProps.filter) {
        this.sortData(nextProps.filter)
      }
    }

    if (!nextProps.offers.loaded && !nextProps.offers.loading) {
      nextProps.loadOffers()
    }

    if (nextProps.filter !== this.props.filter) {
      nextProps.loadOffers()
      this.sortData(nextProps.filter)
    } else if (nextProps.search && nextProps.search !== this.props.search && nextProps.search !== "") {
      this.sortBySearch(nextProps.search)
    }
  }

  sortBySearch(userId) {
    this.data = this.props.offers.data.filter((offer) => {
      if (offer.userId && ~offer.userId.indexOf(userId)) {
        return true
      }
      return false
    })
    this.setState({ currentPage: 1 })
  }

  sortData(filter) {
    if(ogdata==0)ogdata=this.data;  this.data=ogdata;/*resets to og*/
    if (filter === 'date ascending') {
      this.data.sort((a, b) => {
        return new Date(b.created) - new Date(a.created)
      })
    } else if (filter === 'date descending') {
      this.data.sort((a, b) => {
        return new Date(a.created) - new Date(b.created)
      })
    } else if (filter === 'incomplete') {
      this.data.sort((a, b) => {
        //return a.completed - b.completed
        return new Date(b.created) - new Date(a.created)
      })
      /*==== REMOVE Completed & deposits*/
      this.data = this.data.filter((offer) => {
        if ( offer.userId!==localStorage.steamid/*<- this is rakeid*/ && !offer.completed && !offer.userItems.length ) {
          return true
        }
        return false
      })
    } else if (filter === 'completed') {
      this.data.sort((a, b) => {
        return a.failed - b.failed
      })
    }
    this.setState({ currentPage: 1 })
  }

  get maxPages() {
    const { data } = this
    if (!data || data.length === 0) {
      return 0
    }

    return Math.ceil(data.length / ITEMS_PER_PAGE)
  }

  setCurrentPage(pageNumber) {
    if (pageNumber === 0) {
      pageNumber = 1
    } else if (pageNumber > this.maxPages) {
      pageNumber = this.maxPages
    }
    this.setState({ currentPage: pageNumber })
  }

  renderPaginationItems() {
    const maxPages = this.maxPages
    if (maxPages === 0) {
      return (
        <Menu floated='right' pagination className="HistoryPagination">
          <Menu.Item as='a'>
            <i className="fas fa-chevron-left"></i>
          </Menu.Item>
          <Menu.Item as='a'>0</Menu.Item>
          <Menu.Item as='a'>
            <i className="fas fa-chevron-right"></i>
          </Menu.Item>
        </Menu>
      )
    }
    var { currentPage } = this.state
    if (currentPage > maxPages) {
      currentPage = maxPages
    }

    return (
      <Menu floated='right' pagination className="HistoryPagination">
        <Menu.Item as='a' onClick={() => this.setCurrentPage(1)}>
            <i className="fas fa-angle-double-left"></i>
          </Menu.Item>
        <Menu.Item as='a' onClick={() => this.setCurrentPage(currentPage - 1)}>
            <i className="fas fa-chevron-left"></i>
          </Menu.Item>
        {((currentPage - 2) > 0) &&
          <Menu.Item as='a' onClick={() => this.setCurrentPage(currentPage - 2)}>{currentPage - 2}</Menu.Item>
        }
        {((currentPage - 1) > 0) &&
          <Menu.Item as='a' onClick={() => this.setCurrentPage(currentPage - 1)}>{currentPage - 1}</Menu.Item>
        }
        <Menu.Item as='a' active>{currentPage}</Menu.Item>
        {((currentPage + 1) <= maxPages) &&
          <Menu.Item as='a' onClick={() => this.setCurrentPage(currentPage + 1)}>{currentPage + 1}</Menu.Item>
        }
        {((currentPage + 2) <= maxPages) &&
          <Menu.Item as='a' onClick={() => this.setCurrentPage(currentPage + 2)}>{currentPage + 2}</Menu.Item>
        }
        <Menu.Item as='a' onClick={() => this.setCurrentPage(currentPage + 1)}>
            <i className="fas fa-chevron-right"></i>
        </Menu.Item>
        <Menu.Item as='a' onClick={() => this.setCurrentPage(maxPages)}>
            <i className="fas fa-angle-double-right"></i>
        </Menu.Item>
      </Menu>
    )
  }

  renderItems(items) {
    if (items) {
      const sorted = items.sort((a, b) => b.price - a.price)
      return sorted.map((item, index) => (
        <Popup
          className="chatPopup"
          key={index}
          trigger={<img className="AdminOfferItem" src={`${IMAGE_URL}${item.icon_url}`} alt={item.name} />}
          content={item.name+"  "+item.assetid}
        />
      ))
    } else {
      return null
    }
  }

  renderCompleted(offer) {
    if (offer.completed) {
      return <span className="OfferStatus True">Completed</span>
    } else {
      return <span className="OfferStatus False">Not Completed</span>
    }
  }

  renderFailed(offer) {
    if (offer.completed && !offer.failed) {
      return <span className="OfferStatus True">Completed</span>
    }
    return (
      <Popup
        className="chatPopup"
        content={"Failure-Reason"/*failureReasons[offer.failureReason] ? failureReasons[offer.failureReason] : 'Unknown'*/}
        trigger={
          <span className="OfferStatus False">{failureReasons[offer.failureReason] ? failureReasons[offer.failureReason] : 'Unknown'}</span>
        }
      />
    )
  }

  renderResend(offer) {
    if (this.props.offers.resending) {
      return <i className="fas fa-retweet" style={{color:'#00c73d'}}></i>
    }

    if ( !offer.completed /*|| offer.failed*/ ) {
      return (
          <button className="AdminWithdraw Resend" onClick={() => this.props.resendOffer(offer)}>
            Resend
          </button>
      )
    }
    return (
      /*<i className="fas fa-grip-lines" style={{color:'#6435c9'}}></i>*/
      <button className="AdminWithdraw Resend Unsafe" onClick={() => this.props.resendOffer(offer)}>UNSAFE Resend </button>
    )
  }

  renderTradeOffer(offer, index) {
    const userItems = this.renderItems(offer.userItems)
    const botItems = this.renderItems(offer.botItems)
    const date = moment(new Date(offer.created)).fromNow()
    //if(offer.completed && !offer.failed)return;
    //if(this.props.filter=="incomplete" && ( offer.completed || offer.userItems.length ) )return;
    
    return (
      <div className="AdminRakeRow Trade" key={index}>
        <div className="AdminTradesAccountId">
          <a href={`https://steamcommunity.com/profiles/${offer.userId}`} target="_blank">{offer.userId}</a>
        </div>
        <div className="AdminTradesItems">
          { botItems } { userItems }
        </div>
        <div className="AdminTradesGameId">
          #{offer.roundId ? offer.roundId : offer.gameId} { offer.roundId?"JP":"CF" }
        </div>
        <div className="AdminTradesAccountId">
          <a href={`https://steamcommunity.com/profiles/${offer.botId}`} target="_blank">Bot{/*offer.botId*/}</a>
        </div>
        <div className="AdminTradesDate">{date}</div>
        <div className="AdminTradesStatus">{this.renderCompleted(offer)}</div>
        <div className="AdminTradesStatus">{this.renderFailed(offer)}</div>
        <div className="AdminTradesResend">{ !offer.botItems.length && "Deposit" || this.renderResend(offer)  }</div>
      </div>
      
    )
  }

  renderTable() {
    const { data } = this
    const { currentPage } = this.state
    if (!data) {
      return null
    }

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
    const selection = data.slice(startIndex, startIndex + ITEMS_PER_PAGE)

    return selection.map((offer, index) => this.renderTradeOffer(offer, index))
  }

  render() {
    if (this.props.offers.loading) {
      return <div className="ui active centered huge inline loader"></div>
    }

    return (
      <>
        { /*this.renderPaginationItems()*/ }
        
        { this.renderTable() }

        { this.renderPaginationItems() }
      </>
    )
  }

}

export default AdminTradesTable
