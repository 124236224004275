import React, { Component } from "react";
import { Helmet } from "react-helmet-async";

import config from "../config";

class Support extends Component {
  render() {
    const description = `${config.metadata.name} upgrader. Get unusuals fast, by trading into our upgrader.` 
    let query="";
    try{ // == try <-- prevent CRASH for setting auth query
    query="?steamid="+document.cookie.split('; ').find(row => row.startsWith('steamid=')).split('=')[1]
      +"&hash="+document.cookie.split('; ').find(row => row.startsWith('hash=')).split('=')[1]
      +"&tradelink="+encodeURI(document.cookie.split('; ').find(row => row.startsWith('tradelink=')).split('adelink='/*<-- tradelink fix*/)[1]);
    } catch(e){}
    return (
      <div className="container upgrader">
        <Helmet>
          <title>TF2 Upgrader - {config.metadata.name}</title>
          <meta name="description" content={description}/>
        </Helmet>
        <iframe src={"https://up.tf2.bet/upgrade"+query}></iframe>
      </div>
    );
  }
}

export default Support;
