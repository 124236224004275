import React, { Component } from 'react';
import Modal from 'react-modal';
import { Popup } from 'semantic-ui-react'
import CountUp from 'react-countup';

import {
  getUserTotal,
  getCoinflipTotal,
  didCreatorWin,
} from '../../../util/coinflip';
import CountDownTimer from '../../CountDownTimer/CountDownTimer';
import './CoinflipWatchModal.css';
import '../customModals.css';


import config from '../../../config';

const black = "Black";
const red = "Red";

const IMAGE_URL = "https://steamcommunity-a.akamaihd.net/economy/image/";
const WAITING_COUNTDOWN = 120;
const COMPLETION_COUNTDOWN = 10;

class CoinflipWatchItem extends Component {
  render() {
    return (
      <Popup 
        className="chatPopup"
        size='mini'
        position="top center"
        content={this.props.item.name}
        trigger={
          <div className="Item">
            <img src={`${IMAGE_URL}${this.props.item.icon_url}`} alt="{this.props.item.name}" />
            <div className="ItemPrice">
              ${Number(this.props.item.price).toFixed(2)}
            </div>
          </div>
        }
      />
    );
  }
}

export default class CoinflipWatchModal extends Component {
  renderGame() {
    const { game } = this.props;
    return (
      <div className="CoinflipBody">
        {this.renderUser(
          game.creator,
          game.startingSide === "black" ? black : red,
          this.getCreatorPercent(game),
          this.props.hasGameFlipped(game)
            ? didCreatorWin(game)
              ? "winner"
              : "loser"
            : ""
        )}
        {this.renderUser(
          game.joiner,
          game.startingSide === "black" ? red : black,
          this.getJoinerPercent(game),
          this.props.hasGameFlipped(game)
            ? didCreatorWin(game)
              ? "loser"
              : "winner"
            : ""
        )}
        <div className="CoinflipStatus">{this.getStatus()}</div>
        <span className="Divider"></span>
      </div>
    );
  }

  flipCoin(game, winningSide) {
    const id = (" " + game._id).slice(1);
    const percentage = game.winningPercentage
    if (!this.props.hasGameFlipped(game)) {
      setTimeout(() => {
        this.props.setCoinFlipped(id);
        this.forceUpdate();
      }, 6000);
    }
    return (
      <div className="Flipper">
        <div className={`CoinFlip ${winningSide === "black" ? black : red}${percentage < 25 || percentage > 75 ? `-2`:``}`}></div>
      </div>
    );
  }

  displayWinner(winningSide, { winningPercentage, secret }) {
    const percentage = winningPercentage
    return (
      <div className="Flipper">
        <div className={`CoinFlip ${winningSide === "black" ? black : red}${percentage < 25 || percentage > 75 ? `-2`:``}`}></div>
      </div>
    )
  }

  showGameHiddenInfo() {
    const { game } = this.props;
    if (this.props.hasGameFlipped(game)) {
      return (
        <div className="CoinflipFooterContent">
        <div className="FooterCoinflipInfo">
        <span className="Hash">Hash: {game.hash}</span>
          <span className="Percent">Roll: {game.winningPercentage}</span>
          <span className="Secret">Secret: {game.secret}</span>
        </div>
        <button onClick={this.props.onClose}>Close</button>
      </div>
      );
    } else {
      return (
        <div className="CoinflipFooterContent">
        <div className="FooterCoinflipInfo">
          <span className="Hash">Hash: {game.hash}</span>
        </div>
        <button  onClick={this.props.onClose}>Close</button>
      </div>
      )
    }
    
  }

  getStatus() {
    const { game } = this.props;
    if (game.joiner.id && !game.completed) {
      /* waiting for the joiner to accept trade (120 second cooldown) */
      const { dateJoined } = game;
      let secondsSinceJoined = Math.ceil(parseInt(
        (new Date().getTime() - new Date(dateJoined).getTime()) / 1000
      ));

      if (secondsSinceJoined > WAITING_COUNTDOWN) {
        secondsSinceJoined = WAITING_COUNTDOWN; //hopefully this never happens ;)
      }

      return (
        <div className="CounterBox">
          <CountDownTimer
          secondsRemaining={WAITING_COUNTDOWN - secondsSinceJoined}
          totalSeconds={WAITING_COUNTDOWN}
          color="#ffc900"
        />
        </div>
        
      );
    } else if (game.completed) {
      /* game has completed, countdown the timer then flip or just render the winner */
      const { dateCompleted } = game;
      let secondsSinceCompleted = Math.ceil(parseInt(
        (new Date().getTime() - new Date(dateCompleted).getTime()) / 1000
      ));

      if (secondsSinceCompleted >= COMPLETION_COUNTDOWN) {
        if (this.props.hasGameFlipped(game)) {
          return this.displayWinner(didCreatorWin(game) ? game.startingSide : (game.startingSide === 'black' ? 'red' : 'black'), game)
        }
        return this.flipCoin(game, didCreatorWin(game) ? game.startingSide : (game.startingSide === 'black' ? 'red' : 'black'))
      }

      return (
        <div className="CounterBox">
          <CountDownTimer
          secondsRemaining={COMPLETION_COUNTDOWN - secondsSinceCompleted}
          totalSeconds={COMPLETION_COUNTDOWN}
          color="#00c73d"
          onComplete={() => {
            this.forceUpdate();
          }}
        />
        </div>
        
      );
    } else {
      return <span>Waiting...</span>;
    }
  }

  getSecondsElapsed(time) {
    if (!time) {
      return 0;
    }
    return parseInt((new Date().getTime() - time));
  }

  getCreatorPercent(game) {
    if (game.joiner.items.length === 0) {
      return "--";
    }
    const total = getCoinflipTotal(game);
    const creatorTotal = getUserTotal(game.creator);
    return Number((creatorTotal / total) * 100).toFixed(3);
  }

  getJoinerPercent(game) {
    if (game.joiner.items.length === 0) {
      return "--";
    }
    const total = getCoinflipTotal(game);
    const joinerTotal = getUserTotal(game.joiner);
    return Number((joinerTotal / total) * 100).toFixed(2);
  }

  renderUser(user, side, percent, resultClass) {
    if (!user.image) {
      return (
        <div className={`CoinflipPlayer ${side}`}>
          <div className="CoinflipUser">
            <div className="NoPlayer"></div>
            <span className="UserName Waiting">Waiting for player...</span>
          </div>
        </div>
      );
    }
    return (
      
      <div className={`CoinflipPlayer ${resultClass} ${side}`}>
        <div className="CoinflipUser">
          <img src={user.image} alt={user.name} />
          <div className="UserName">
            {config.coinflip.botIds.includes(user.id) &&
              <span className={`BotIndicator ${side}`}>BOT</span>
            }
            <span>{user.name}</span>
          </div>
          <span className="UserValue"></span>
        </div>
        {this.renderItems(user, percent)}
      </div>
    );
  }

  renderItems(user, percent) {
    if (user.items.length === 0) {
      return <div className="Waiting">Waiting...</div>;
    }
    const items = this.sortItems(user.items).map((item, key) => (
      <CoinflipWatchItem item={item} key={key} />
    ));
    return (
      <div className="CoinflipUserPanel">
        <div className="UserPanelInfo">
          <CountUp className="Chances" start={0} end={percent} duration={1} decimals={3} suffix="%"/>
          <CountUp className="Value" start={0} end={getUserTotal(user)} duration={1} decimals={2} prefix="$"/>
        </div>
        <div className="UserPanelItems">{items}</div>
      </div>
    );
  }

  sortItems(items) {
    return items.sort((a, b) => b.price - a.price);
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onClose}
        closeTimeoutMS={200}
        onAfterOpen={this.afterOpen}
        contentLabel="Modal"
        className="Modal ModalCoinflipWatch"
        overlayClassName="Modal__Overlay"
      >
        <div className="ModalContent">
          {this.props.game && this.renderGame()}
        </div>
        <div className="ModalFooter">
          {this.props.game ? this.showGameHiddenInfo() : ""}
        </div>
      </Modal>
    );
  }
}
