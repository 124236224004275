export const USERS_CONNECTED_REQUEST    = Symbol('USERS_CONNECTED_REQUEST')
export const USERS_CONNECTED_FAILURE    = Symbol('USERS_CONNECTED_FAILURE')
export const USERS_CONNECTED_SUCCESS    = Symbol('USERS_CONNECTED_SUCCESS')

export const SEND_CHAT_REQUEST          = Symbol('SEND_CHAT_REQUEST')
export const SEND_CHAT_FAILURE          = Symbol('SEND_CHAT_FAILURE')
export const SEND_CHAT_SUCCESS          = Symbol('SEND_CHAT_SUCCESS')
export const RECEIVE_CHAT               = Symbol('RECEIVE_CHAT')
export const LOAD_CHAT_REQUEST          = Symbol('LOAD_CHAT_REQUEST')
export const LOAD_CHAT_SUCCESS          = Symbol('LOAD_CHAT_SUCCESS')
export const LOAD_CHAT_FAILURE          = Symbol('LOAD_CHAT_FAILURE')

export const SAVE_TRADE_URL_REQUEST     = Symbol('SAVE_TRADE_URL_REQUEST')
export const SAVE_TRADE_URL_SUCCESS     = Symbol('SAVE_TRADE_URL_SUCCESS')
export const SAVE_TRADE_URL_FAILURE     = Symbol('SAVE_TRADE_URL_FAILURE')

export const REQUEST_INVENTORY          = Symbol('REQUEST_INVENTORY')
export const REQUEST_INVENTORY_SUCCESS    = Symbol('REQUEST_INVENTORY_SUCCESS')
export const REQUEST_INVENTORY_FAILURE    = Symbol('REQUEST_INVENTORY_FAILURE')
export const FORCE_REQUEST_INVENTORY      = Symbol('FORCE_REQUEST_INVENTORY')

export const BAN_USER = Symbol('BAN_USER')
export const BAN_USER_SUCCESS = Symbol('BAN_USER_SUCCESS')
export const BAN_USER_FAILURE = Symbol('BAN_USER_FAILURE')

export const MUTE_USER = Symbol('MUTE_USER')
export const MUTE_USER_SUCCESS = Symbol('MUTE_USER_SUCCESS')
export const MUTE_USER_FAILURE = Symbol('MUTE_USER_FAILURE')

export const DELETE_MESSAGE = Symbol('DELETE_MESSAGE')
export const DELETE_MESSAGE_SUCCESS = Symbol('DELETE_MESSAGE_SUCCESS')
export const DELETE_MESSAGE_FAILURE = Symbol('DELETE_MESSAGE_FAILURE')

export const CREATE_REFERRAL_CODE = Symbol('CREATE_REFERRAL_CODE')
export const CREATE_REFERRAL_CODE_SUCCESS = Symbol('CREATE_REFERRAL_CODE_SUCCESS')
export const CREATE_REFERRAL_CODE_FAILURE = Symbol('CREATE_REFERRAL_CODE_FAILURE')

export const GET_REFERRAL_DATA = Symbol('GET_REFERRAL_DATA')
export const GET_REFERRAL_DATA_SUCCESS = Symbol('GET_REFERRAL_DATA_SUCCESS')
export const GET_REFERRAL_DATA_FAILURE = Symbol('GET_REFERRAL_DATA_FAILURE')

export const WITHDRAW_REFERRAL_EARNINGS = Symbol('WITHDRAW_REFERRAL_EARNINGS')
export const WITHDRAW_REFERRAL_EARNINGS_SUCCESS = Symbol('WITHDRAW_REFERRAL_EARNINGS_SUCCESS')
export const WITHDRAW_REFERRAL_EARNINGS_FAILURE = Symbol('WITHDRAW_REFERRAL_EARNINGS_FAILURE')


